import { defineStore } from 'pinia';

import {orgApi} from "@/utils/Api.util";

import { ErrorMessage } from "@/stores/errors/ErrorMessage";
import { Observation } from "@/models/Observation.model";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { Pupil } from "@/models/Pupil.model";
import { PaginationState } from "@/models/PaginationState.model";
import {DateTime, Interval} from "luxon";
import { useClassroomsStore } from "@/stores/Classrooms.store";

interface ObservationStoreState {
	observations: Observation[],
	errorMessage: ErrorMessage | null,
	emptyObservation: Observation | null,
	currentPage: PaginationState | null
}

export class ObservationFilters {
	public pupilId: string;
	public activityId: string;
	public dateRange: Interval | null;
	public keywords: string;
}

export const useObservationsStore = defineStore('observations', {

	state: (): ObservationStoreState => ({
		observations: [],
		errorMessage: null,
		emptyObservation: null,
		currentPage: null
	}),

	actions: {
		async load(page: PaginationState | null = null, filter: ObservationFilters | null) {
			const params = {
				page: page ? page.currentPage : null,
				mask : this.getMask().join(',')
			};

			params['classroomIds'] = useClassroomsStore().currentClassroom.id;

			if (filter) {
				if (filter.pupilId) {
					params['pupilIds'] = filter.pupilId;
				}

				if (filter.activityId) {
					params['activityIds'] = filter.activityId;
				}

				if (filter.dateRange) {
					params['start'] = filter.dateRange.start.toJSDate();
					params['end'] = filter.dateRange.end.toJSDate();
				}

				if (filter.keywords) {
					params['query'] = filter.keywords;
				}
			}

			const response = await orgApi.get('observations', {
				params: params
			});

			this.observations = response.data.data.map((observation: any) => {
				return Observation.mapFromServer(observation);
			});

			this.currentPage = PaginationState.mapFromServer(response.data.meta);

		},

		new(pupil: Pupil) {
			let newObservation = new Observation(pupil);
			newObservation.classroomId = useClassroomsStore().currentClassroom.id;

			newObservation.note = "";

			return newObservation;
		},

		getMask() {
			return [
				'*',
				'files.*',
				'files.variations.*',
				'classroom.id',
				'pupil.*',
				'pupil.icon.*',
				'pupil.classrooms.id',
				'pupilActivity.*',
				'pupilActivity.lessonItem.*',
				'pupilActivity.lessonItem.icon.*',
				'pupilActivity.lessonItem.lesson.*',
				'pupilActivity.lessonItem.activity.*',
				'pupilActivity.lessonItem.lessonPlan.*',
			];
		},

		async create() {

			this.errorMessage = null;

			const observation = this.emptyObservation;
			if (observation === null) {
				return;
			}

			try {
				const response = await orgApi.post('observations', observation.getServerData());
				observation.id = response.data.data.id;

				this.emptyObservation = null;

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async save(observation: Observation) {

			this.errorMessage = null;

			if(observation.id) {
				// update existing observation
				try {
					const response = await orgApi.put("observations/" + observation.id, observation.getServerData(), {
						params: {
							mask : [
								'*',
								'files.*',
							].join(',')
						}
					});
					observation.setFromServerData(response.data.data);
					this.updateStoreItem(observation);

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			} else {
				// create new observation
				try {
					const response = await orgApi.post("observations", observation.getServerData(), {
						params: {
							mask : this.getMask().join(',')
						}
					});
					observation.setFromServerData(response.data.data);
					this.addStoreItem(observation);

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}
		},

		async delete(observation: Observation) {

			try {
				const response = await orgApi.delete("observations/" + observation.id);
				this.deleteStoreItem(observation);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		updateStoreItem(observation: Observation) {
			var storeIndex = this.observations.findIndex(
				storeItem => storeItem.id == observation.id
			);
			this.observations[storeIndex] = observation.clone();
		},

		addStoreItem(observation: Observation) {
			this.observations.unshift(observation);
		},

		deleteStoreItem(observation: Observation) {
			const index = this.observations.findIndex(v => v.id === observation.id);
			if (index >= 0) {
				this.observations.splice(index, 1);
			}
		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		async refreshObservation(observation: Observation) {
			const response = await orgApi.get('observations/' + observation.id, {
				params: {
					mask: this.getMask().join(',')
				}
			});

			observation.setFromServerData(response.data.data);
		}
	}

});
