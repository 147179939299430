import config from '@/config/app.config';

export const OVSG_CURRICULUM_SOURCE_NAME = 'OVSG';
export const OVSG_SELECTOR_DOMAIN = 'https://leerlokaal.ovsg.be';

let path: string;
if (config.ENV === 'development') {
	//path = '/doelenkiezer-demo?skip_domein_validatie=1';
	path = '/?skip_domein_validatie=1';
} else {
	path = '/';
}

export const OVSG_SELECTOR_PATH = path;

export const OVSG_PROXY_API_PATH = '/proxy/ovsg';
