<template>
	<div>
		<VueDatePicker
			range
			:multi-calendars="{}"
			:preset-dates="presetRanges"
			v-bind="$attrs"
			:locale="locale"
			:format="datePickerDateFormat"
			:action-row="{showSelect: false, showCancel: false}"
			auto-apply
			:enable-time-picker="false"
			position="left"
		>
			<template #dp-input="{ value, onInput, onEnter, onTab, onClear, onBlur, onKeypress, onPaste, isMenuOpen }">
				<div class="flex items-center">
					<CalendarDaysIcon class="w-5 h-5 absolute top-1 left-2 text-base-content-light z-10" />
					<input type="text"
						class="input input-sm input-bordered w-full pl-10"
						:value="value" :placeholder="$t('Date range')"
						:readonly="$attrs['readonly']"
						:disabled="$attrs['disabled']"
					/>
				</div>
			</template>

			<template #preset-date-range-button="{ label, value, presetDate }">
				<button type="button" class="btn btn-sm btn-ghost normal-case whitespace-nowrap justify-start hover:bg-accent"
					:tabindex="0"
					@click="presetDate(value)"
					@keyup.enter.prevent="presetDate(value)"
				>
					{{ label }}
				</button>
			</template>
		</VueDatePicker>
	</div>
</template>


<style>
:root {
	--dp-border-radius: 0.375rem;
}
.dp__theme_light {
	--dp-primary-color: #6366f1;
	--dp-range-between-dates-background-color: #e0e7ff;
	--dp-range-between-border-color: #e0e7ff;
}
.dp--preset-dates {
	@apply sm:pt-2 flex sm:flex-col sm:gap-1;
}
.dp__outer_menu_wrap {
	@apply shadow-md;
}
</style>


<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { CalendarDaysIcon } from '@heroicons/vue/24/outline';
import { DateTime } from 'luxon';

export default {
	inheritAttrs: false,

	components: {
		VueDatePicker,
		CalendarDaysIcon,
  	},

	computed: {
		locale() {
			return this.$i18n.locale;
		},

		datePickerDateFormat() {
			const dateFormat = {
 				 year: "2-digit",
				 month: "short",
				 day: "2-digit"
			};
			return (dateRange) => {
				return [
					dateRange[0]?.toLocaleDateString(this.locale, dateFormat),
					dateRange[1]?.toLocaleDateString(this.locale, dateFormat)
				].join(' - ');
			}
		},

		presetRanges() {
			const today = DateTime.local();
			const yesterday = today.minus({days: 1});
			const lastMonday = today.minus({days: today.weekday - 1});
			const firstDayPreviousWeek = lastMonday.minus({days: 7});
			const lastDayPreviousWeek = lastMonday.minus({days: 1});
			const firstDayThisMonth = DateTime.local(today.year, today.month, 1);
			const firstDayPreviousMonth = today.minus({ months: 1 }).startOf('month');
			const lastDayPreviousMonth = firstDayThisMonth.minus({ days: 1 });
			const firstDayOfSchoolYear = today.month < 9 ? DateTime.local(today.year - 1, 9, 1) : DateTime.local(today.year, 9, 1);

			return [
				{
					label: this.$t('Today'),
					value: [ today, today ],
					slot: 'preset-date-range-button'
				},
				{
					label: this.$t('Yesterday'),
					value: [ yesterday, yesterday ],
					slot: 'preset-date-range-button'
				},
				{
					label: this.$t('This week'),
					value: [ lastMonday, today ],
					slot: 'preset-date-range-button'
				},
				{
					label: this.$t('Last week'),
					value: [ firstDayPreviousWeek, lastDayPreviousWeek ],
					slot: 'preset-date-range-button'
				},
				{
					label: this.$t('This month'),
					value: [ firstDayThisMonth, today ],
					slot: 'preset-date-range-button'
				},
				{
					label: this.$t('Last month'),
					value: [ firstDayPreviousMonth, lastDayPreviousMonth ],
					slot: 'preset-date-range-button'
				},
				{
					label: this.$t('This school year'),
					value: [ firstDayOfSchoolYear, today ],
					slot: 'preset-date-range-button'
				},
			];
		}
	},
}
</script>
