<template>

	<Modal @requestUnmount="hide" :fullscreen="false" width="2xl">

		<template #header>
			<div class="font-semibold text-xl mb-4" v-text="inUpdateMode? $t('Edit school') : $t('Add school')"></div>
		</template>

		<template #default="modalActions">

			<form @submit.prevent="save" class="flex-grow flex flex-col overflow-y-auto overflow-x-hidden">
				<div class="flex-grow overflow-auto grid grid-cols-6 gap-10 row mb-6">

					<div class="col-span-6">
						<sms-input type="text" autofocus v-model="editableModel.name" :label="$t('Name')"
											 :validationError="organisationsStore.errorMessage?.fields.name"></sms-input>
					</div>

					<div class="col-span-6 sm:col-span-3">
						<sms-input type="text" v-model="editableModel.account_id" :label="$t('Account id')"
											 :validationError="organisationsStore.errorMessage?.fields.account_id"></sms-input>
					</div>

					<div class="col-span-6 sm:col-span-3">
						<sms-select v-model="editableModel.subscription_plan"
							@change="handleChangedSubscriptionPlan"
							class="capitalize"
							:label="$t('Subscription plan')"
							:validationError="organisationsStore.errorMessage?.fields.subscription_plan"
							:options="[
								{
									value: 'trial',
									label: $t('Trial')
								},
								{
									value: 'paying',
									label: $t('Paying')
								},
								{
									value: 'internal',
									label: $t('Internal')
								},
						]">
						</sms-select>
					</div>

					<div class="col-span-6 sm:col-span-3">
						<sms-select v-model="editableModel.locale" :label="$t('Language')" class="capitalize"
												:options="locales"></sms-select>
					</div>

					<div class="col-span-6 sm:col-span-3">
						<sms-select v-model="editableModel.country" :label="$t('Country')" class="capitalize"
									:options="countries"></sms-select>
					</div>

					<div class="col-span-6 sm:col-span-3">
						<sms-input type="date" v-model="editableModel.termination_date" :label="$t('Termination date')"
											 :validationError="organisationsStore.errorMessage?.fields.termination_date"
											 :min="(new Date()).toISOString().split('T')[0]"
						>
						</sms-input>
					</div>

					<div class="col-span-6 sm:col-span-3">
						<sms-select v-model="editableModel.curriculum"
									class="capitalize"
									:label="$t('Curriculum')"
									:validationError="organisationsStore.errorMessage?.fields.curriculum"
									:options="[
								{
									value: null,
									label: ''
								},
								{
									value: 'ZiLL',
									label: 'ZiLL'
								},
								{
									value: 'GO_2023',
									label: 'GO!'
								},
								{
									value: 'SLO_2023',
									label: 'SLO (Nederland)'
								},
								{
									value: 'Greece_2024',
									label: 'Grieks Curriculum'
								},
								{
									value: 'OVSG',
									label: 'OVSG'
								}
						]">
						</sms-select>
					</div>

					<template v-if="!inUpdateMode">
						<div class="col-span-6 font-bold text-lg border-b">
							{{ ('Account owner') }}
						</div>

						<div class="col-span-6 sm:col-span-3">
							<sms-input type="email" :label="$t('Email address')" :validationError="organisationsStore.errorMessage?.fields.email" v-model="ownerEmailAddress"></sms-input>
						</div>

						<div class="col-span-6">
							<input type="checkbox" id="send-invite" checked class="checkbox" v-model="sendInvitation" />
							<label for="send-invite" class="label-text pl-2 cursor-pointer">{{
									$t('Send an invitation email')
								}}</label>
						</div>
					</template>

					<template v-if="inUpdateMode">

						<div class="col-span-6 ">
							<p class="font-bold text-lg border-b">{{ ('Agenda settings') }}</p>
							<p class="alert alert-warning text-sm py-1 mt-6 items-start">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"></path></svg>
								{{ $t('Not required.') }}
								{{ $t('Only set school hours if deviating from regular school hours.') }}</p>
						</div>

						<div class="col-span-6 sm:col-span-3">
							<sms-input type="time" v-model="editableModel.agenda_settings.school_hours_start" :label="$t('School hours start')"
							   :validationError="organisationsStore.errorMessage?.fields.agenda_settings?.school_hours_start"
							   step="60"
							>
							</sms-input>
						</div>

						<div class="col-span-6 sm:col-span-3">
							<sms-input type="time" v-model="editableModel.agenda_settings.school_hours_end" :label="$t('School hours end')"
							   :validationError="organisationsStore.errorMessage?.fields.agenda_settings?.school_hours_end"
							   step="60"
							>
							</sms-input>
						</div>

						<div class="col-span-6">
							<input type="checkbox" id="include-weekends" checked class="checkbox" v-model="editableModel.agenda_settings.include_weekends" />
							<label for="include-weekends" class="label-text pl-2 cursor-pointer">{{$t('Include weekends in agenda') }}</label>
						</div>

					</template>

				</div>

				<div class="modal-footer flex" :class="[inUpdateMode? 'justify-between' : 'justify-end']">

					<!--
					<Dropdown v-if="inUpdateMode" position="top-start">
						<template #trigger>
							<button type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
								<span v-show="deleting" class="loading loading-spinner"></span>
								<TrashIcon class="w-5 h-5"/>
							</button>
						</template>
						<template #content>
							<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')" confirm-style="danger"
																		 class="w-80" @confirm="confirmRemove()">
								<span class="text-danger">{{ $t('Are you sure?') }}</span>
							</DropdownDialogConfirm>
						</template>
					</Dropdown>
					-->
					<div class="flex"></div>

					<div class="flex justify-end gap-1">
						<button type="button" class="btn btn-ghost" @click="modalActions.hide"
										:class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>
			</form>

		</template>

	</Modal>
</template>


<script lang="ts">
import {mapStores} from 'pinia';

import {Organisation} from '@/models/Organisation.model';
import {useOrganisationsStore} from "@/stores/Organisations.store";
import {ApiErrors} from "@/stores/errors/ApiErrors";

import {SUPPORTED_LOCALES} from "@/i18n";
import {SUPPORTED_COUNTRIES} from "@/i18n";

import Modal from "@/components/ui/Modal.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import {TrashIcon} from '@heroicons/vue/24/outline';
import {User} from "@/models/User.model";

export default {
	props: {
		organisation: {
			type: Organisation,
			required: false,
		},
	},
	components: {
		Modal,
		Dropdown,
		DropdownDialogConfirm,
		TrashIcon,
	},
	data() {
		return {
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null,
			ownerEmailAddress: null,
			sendInvitation: null
		}
	},
	computed: {
		...mapStores(useOrganisationsStore),

		inUpdateMode() {
			return (this.organisation && this.organisation.id) ? true : false;
		},

		locales() {
			let locales = [];
			for (const [key, value] of Object.entries(SUPPORTED_LOCALES)) {
				locales.push({
					value: key,
					label: value,
				});
			}
			return locales;
		},

		countries() {
			let countries = [];
			for (const [key, value] of Object.entries(SUPPORTED_COUNTRIES)) {
				countries.push({
					value: key,
					label: value,
				});
			}
			return countries;
		}
	},

	methods: {

		hide() {
			this.organisationsStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		setDefaultTrialTerminationDate() {
			const d = new Date();
			// 90 day trial
			d.setDate(d.getDate() + 90);
			this.editableModel.termination_date = d.toISOString().split('T')[0];
		},

		handleChangedSubscriptionPlan(event) {
			if (event.target.value === 'paying' || event.target.value === 'internal') {
				this.editableModel.termination_date = null;
			} else {
				this.setDefaultTrialTerminationDate();
			}
		},

		/*
		async confirmRemove() {

			if (this.deleting) {
				return;
			}
			this.deleting = true;

			try {
				await this.organisationsStore.delete(this.originalModel);
				this.deleting = false;

				this.hide();

				this.$notify({
					text: this.$t('The school has been removed'),
					type: "success",
				});

			} catch (e: any) {
				if (!(e instanceof ValidationErrors)) {
					throw e;
				}
			}
		},*/

		async save(event) {

			if (this.saving) {
				return;
			}
			this.saving = true;

			try {

				// Should we include email address?
				if (this.editableModel.hasId()) {
					await this.organisationsStore.save(this.editableModel);
				} else {
					await this.organisationsStore.create(this.editableModel, this.ownerEmailAddress, this.sendInvitation || false);
				}

				this.saving = false;
				this.hide();

				this.$notify({
					text: this.$t('The school has been saved'),
					type: "success",
				});

			} catch (e) {
				// error message is populated now.
				this.saving = false;
				if (!(e instanceof ApiErrors)) {
					this.$notify({
						title: "Error!",
						text: e.toString(),
						type: "error"
					});
					throw e;
				}
			}
		}
	},

	beforeMount() {
		if (this.organisation && this.organisation.id) {
			this.originalModel = this.organisation;
			// clone the store model to allow for manipulation without instantly affecting the store
			this.editableModel = this.organisation.clone();

		} else {
			this.editableModel = this.organisationsStore.new();
			this.setDefaultTrialTerminationDate();
		}
	},
}
</script>
