<template>
	<form @submit.prevent="applyFilter" class="grid grid-cols-5 gap-3 pb-4 mb-4 border-b">
		<div class="col-span-2 xl:col-span-1">
			<DateRangeSelector v-model="dateRangeArray" :clearable="false" />
		</div>
		<div class="col-span-2 xl:col-span-1">
			<sms-select v-model="localFilter.pupilId" label="" class="select-sm leading-4 capitalize w-40" :options="pupilsForFilter"></sms-select>
		</div>
		<div class="col-span-2 xl:col-span-1">
			<sms-select v-model="localFilter.activityId" label="" class="select-sm leading-4 capitalize w-40" :options="activitiesForFilter"></sms-select>
		</div>
		<div class="relative col-span-2 xl:col-span-1">
			<div class="absolute inset-y-0 left-0 pl-3 flex pt-2 md:pt-0 md:items-center pointer-events-none">
				<MagnifyingGlassIcon class="w-5 h-5 text-base-content-light" />
			</div>
			<div class="flex flex-wrap -mx-3">
				<div class="w-full px-3 md:mb-0">
					<sms-input v-model="localFilter.keywords" name="search" minlength="3" type="search" class="input-sm pl-10 w-full" />
				</div>
			</div>
		</div>
		<div class="col-span-2 xl:col-span-1 flex justify-start">
			<button type="submit" class="btn btn-primary btn-sm">{{ $t('Apply') }}</button>
			<button v-show="filterApplied" @click="handleFilterReset" type="button" class="btn btn-ghost btn-sm">{{ $t('Reset') }}</button>
		</div>
	</form>
</template>



<script lang="ts">
import { mapStores } from "pinia";
import { useActivitiesStore } from "@/stores/Activities.store";
import { usePupilsStore } from '@/stores/Pupils.store';

import DateRangeSelector from "./ui/DateRangeSelector.vue";
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { ObservationFilters } from "@/stores/Observations.store";
import { DateTime, Interval } from 'luxon';

export default {

	props: {
		filter: {
			type: ObservationFilters,
			default: () => {
				const out = new ObservationFilters();
				out.pupilId = '';
				out.activityId = '';
				out.dateRange = Interval.fromDateTimes(DateTime.now().minus({ days: 1 }), DateTime.now());
				out.keywords = '';
				return out;
			}
		},
	},

	components: {
		DateRangeSelector,
		MagnifyingGlassIcon,
	},

	data() {
		return {
			localFilter: this.filter,
		}
	},

	computed: {
		...mapStores(usePupilsStore, useActivitiesStore),

		dateRangeArray: {
			get(): [Date, Date] {
				if(!this.localFilter.dateRange) {
					return null;
				} else {
					return [
						this.localFilter.dateRange.start,
						this.localFilter.dateRange.end
					];
				}
			},
			set(value: [Date, Date]) {
				this.localFilter.dateRange = {
					start: DateTime.fromJSDate(value[0]),
					end: DateTime.fromJSDate(value[1]),
				};
			}
		},

		filterApplied() {
			return this.localFilter.dateRange
				|| this.localFilter.pupilId
				|| this.localFilter.activityId
				|| this.localFilter.keywords;
		},

		activitiesForFilter() {
			let list = [];
			list.push({
				value: '',
				label: this.$t('All activities'),
			});
			list.push({
				value: '',
				label: '-----------------',
				disabled: true,
			});

			for (let activity of this.activitiesStore.activities) {
				list.push({
					value: activity.id,
					label: activity.name,
				});
			}
			return list;
		},

		pupilsForFilter() {
			let list = [];
			list.push({
				value: '',
				label: this.$t('All pupils'),
			});
			list.push({
				value: '',
				label: '-----------------',
				disabled: true,
			});

			for (let pupil of this.pupilsStore.pupils) {
				list.push({
					value: pupil.id,
					label: pupil.firstName + ' ' + pupil.lastName,
				});
			}
			return list;
		},
	},

	beforeMount() {
		this.initializeFilter();
	},

	mounted() {
		this.loadPupils();
		this.loadActivities();
	},

	methods: {
		async loadActivities() {
			await this.activitiesStore.load();
		},

		async loadPupils() {
			await this.pupilsStore.load();
		},

		applyFilter() {
			// wait 1 ms to let the v-model update
			this.$emit('apply-filter-request', this.localFilter);
		},

		initializeFilter() {
			this.localFilter = {
				pupilId: '',
				activityId: '',
				dateRange: null,
				keywords: '',
			};
		},

		handleFilterReset() {
			this.initializeFilter();
			this.$emit('apply-filter-request', this.localFilter);
		},
	},
}

</script>
