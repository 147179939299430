import { defineStore } from 'pinia';
import { ErrorMessage } from "@/stores/errors/ErrorMessage";
import { Goal } from "@/models/Goal.model";
import { orgApi } from '@/utils/Api.util';
import { ApiErrors } from './errors/ApiErrors';
import { FilterDef } from "@/utils/FilterDefs.util";
import { GoalSource } from "@/utils/GoalSource";

export class GoalFilterDef extends FilterDef {
	constructor() {
		super(['mainGroups', 'keywords', 'tags']);
	}
}

interface GoalsStoreState {
	items: Goal[],
	errorMessage: ErrorMessage | null,
	loadingPromise: Promise<void> | null,
	filter: GoalFilterDef,
	curriculum: string | null,
	usedTags: string[],
	source: GoalSource,
}

export class GoalSearchResult {

	public highlightedTitle: string = null;
	public highlightedCustomTitle: string = null;

	public goal: Goal;

	constructor(goal: Goal) {
		this.goal = goal;
	}

}

export const useGoalsStore = defineStore('goals', {

	state: (): GoalsStoreState => ({
		items: [], // Careful using this one, may contain goals from different sources/curriculums. Recommended to use getter methods.
		errorMessage: null,
		loadingPromise: null,
		filter: new GoalFilterDef(),
		curriculum: null,
		usedTags: [],
		source: new GoalSource(),
	}),

	getters: {

		curriculumItems(state: GoalsStoreState) {

			if(state.curriculum) {
				return state.items.filter((goal) => {
					if(!goal.source || goal.source !== state.curriculum) {
						return false;
					}
					return true;
				});
			} else {
				return [];
			}
		},

		filteredItems(state: GoalsStoreState): GoalSearchResult[] {

			if (!this.curriculumItems.length) {

				return this.curriculumItems.map((goal: Goal) => new GoalSearchResult(goal));

			} else if(this.filter.isEmpty()) {

				// clear any highlighted keywords
				return this.curriculumItems.map(
					(goal: Goal) => {
						return new GoalSearchResult(goal)
					}
				);

			}

			const keywords = this.filter.getParamValues('keywords').map((filterValue) => filterValue.id.toLowerCase());
			const tags = this.filter.getParamValues('tags').map((filterValue) => filterValue.id);
			const mainGroups = this.filter.getParamValues('mainGroups').map((filterValue) => filterValue.id.toLowerCase());

			const searchResults: GoalSearchResult[] = [];
			this.curriculumItems.forEach((goal: Goal): void => {

				if (mainGroups.length > 0) {
					let matchingGroup = false;
					for (let i = 0; i < mainGroups.length; i++) {
						if (goal.sourceParentPath.toLowerCase().startsWith(mainGroups[i] + ".")) {
							matchingGroup = true;
							break;
						}
					}
					if (!matchingGroup) {
						return;
					}
				}

				if (tags.length > 0) {
					// AND logic
					// let allTagsMatch = true;
					// for(let i = 0; i < tags.length; i++) {
					// 	if(!goal.tags || !goal.tags.includes(tags[i])) {
					// 		allTagsMatch = false;
					// 		break;
					// 	}
					// }
					// if(!allTagsMatch) {
					// 	return false;
					// }

					// OR logic
					let someTagsMatch = false;
					for (let i = 0; i < tags.length; i++) {
						if (goal.tags && goal.tags.includes(tags[i])) {
							someTagsMatch = true;
							break;
						}
					}
					if (!someTagsMatch) {
						return;
					}
				}

				let highlightedTitle = null;
				let highlightedCustomTitle = null;

				if (keywords.length > 0) {
					let allKeywordsMatch = true;
					for (let i = 0; i < keywords.length; i++) {

						const regex = new RegExp(keywords[i], 'gi');

						let sourcePathMatches, titleMatches, customTitleMatches = null;
						sourcePathMatches = goal.fullPath.match(regex);
						if (!sourcePathMatches) {
							titleMatches = goal.title.match(regex);
							if (goal.customTitle) {
								customTitleMatches = goal.customTitle.match(regex);
							}
						}

						if (!sourcePathMatches && !titleMatches && !customTitleMatches) {

							allKeywordsMatch = false;
							break;

						} else {

							// escape any html entities in the title variant with the highlighted keywords so it can
							// be safely rendered as HTML, only rendering the tags used for highlighting
							const elForHtmlConversion = document.createElement('p');

							if (titleMatches) {
								if (highlightedTitle === null) {
									elForHtmlConversion.textContent = goal.title;
									highlightedTitle = elForHtmlConversion.innerHTML;
								}

								highlightedTitle = highlightedTitle.replace(regex, (match) => {
									return '<em>' + match + '</em>';
								});
							}

							if (customTitleMatches) {
								if(highlightedCustomTitle === null) {
									elForHtmlConversion.textContent = goal.customTitle;
									highlightedCustomTitle = elForHtmlConversion.innerHTML;
								}

								highlightedCustomTitle = highlightedCustomTitle.replace(regex, (match) => {
									return '<em>' + match + '</em>';
								});
							}
						}

					}

					if (!allKeywordsMatch) {
						return;
					}
				}

				const result = new GoalSearchResult(goal);
				result.highlightedTitle = highlightedTitle;
				result.highlightedCustomTitle = highlightedCustomTitle;

				searchResults.push(result);
			});

			return searchResults;
		}
	},

	actions: {

		new() {
			return new Goal();
		},

		async load() {
			if (!this.curriculum) {
				throw new Error('No curriculum set');
			}

			await Promise.all([
				this.loadGoals(),
				this.source.load(this.curriculum),
			]);
		},

		async loadGoals() {

			if (this.items.length > 0) {
				return this.loadingPromise;
			}

			this.loadingPromise = new Promise<void>(async (resolve)  => {

				let response;
				try {
					response = await orgApi.get('goals', {
						params: {
							mask: this.getMask().join(','),
						}
					});
					this.items = response.data.data.map(Goal.mapFromServer);
					this.updateUsedTags();
				} catch (e) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
				this.sortItems();
				resolve();
			});

			return this.loadingPromise;

		},

		async saveItem(goal:Goal) {
			return this.bulkSave([goal]);
		},

		supplementWithGoalsFromSource() {

			if(!this.source) {
				throw new Error('No source set');
			}
			const goals = this.source.getGoals();

			// @TODO this will not work properly for goals that have no 'code' (and as such have the same 'fullPath')
			// No big issue right now since only used for our own importer which relies on our own data structure (which requires a code for each goal)
			const pathsAlreadyInList = this.items.map((item) => item.source + '|' + item.fullPath);
			const goalsToAdd = goals.filter((goal) => !pathsAlreadyInList.includes(goal.source  + '|' + goal.fullPath));
			this.items.push(...goalsToAdd);

			this.sortItems();

		},

		async deleteItem(item: Goal) {
			item.pendingSync = true;

			const index = this.items.findIndex(storeItem => storeItem.id === item.id);
			if(index < 0) {
				return;
			}

			if(item.id) {
				return new Promise<void>(async resolve => {
					try {
						const response = await orgApi.delete('goals', {
							params: {
								ids: [ item.id ],
								mask: this.getMask().join(','),
							}
						});

						this.items.splice(index, 1);
					} catch (e) {
						this.errorMessage = ApiErrors.fromAxiosException(e);
						throw this.errorMessage;
					}

					resolve();
				});
			} else {
				this.items.splice(index, 1);
			}
			return;
		},

		async bulkSave(itemsToImport: Goal[]) {

			const response = await orgApi.post('goals',
				itemsToImport.map((item) => item.getServerData()),
				{
					params: {
						mask: this.getMask().join(','),
					}
				}
			);

			response.data.data.forEach((item, index) => {
				itemsToImport[index].pendingSync = false;
				itemsToImport[index].id = item.id;
			});

			// Update the store items with the new server data
			itemsToImport.forEach((item) => {
				this.updateStoreItem(item);
			});

			this.updateUsedTags();

		},

		async bulkDelete(itemsToDelete: Goal[]) {

			const response = await orgApi.delete('goals', {
				params: {
					ids: [ itemsToDelete.map(item => item.id) ].join(','),
					mask: this.getMask().join(','),
				}
			});

			// reset goal object
			itemsToDelete.forEach(item => {
				item.pendingSync = false;
				item.id = null;
				item.customTitle = null;
			});
			this.updateUsedTags();

		},

		sortItems() {
			// sort by path
			this.items.sort((a, b) => {

				// const segmentsA = a.sourceParentPath.split('.').map(Number);
				// const segmentsB = b.sourceParentPath.split('.').map(Number);
				const segmentsA = a.fullPath.split('.');
				const segmentsB = b.fullPath.split('.');

				const minLength = Math.min(segmentsA.length, segmentsB.length);

				for (let i = 0; i < minLength; i++) {
					if (segmentsA[i] !== segmentsB[i]) {
						if( /^\d+$/.test(segmentsA[i]) && /^\d+$/.test(segmentsB[i]) ) {
							return segmentsA[i] - segmentsB[i];
						} else {
							return segmentsA[i].localeCompare(segmentsB[i]);
						}
					}
				}

				return segmentsA.length - segmentsB.length;
			});
		},

		removeNonPersistedItems() {
			this.items = this.items.filter((goal) => goal.id !== null);
		},

		updateStoreItem(updatedItem: Goal) {
			const storeIndex = this.items.findIndex(
				storeItem => storeItem.id == updatedItem.id
			);
			this.items[storeIndex] = updatedItem.clone();
			this.updateUsedTags();
		},

		/**
		 * Update the usedTags set with the tags from all the goals in the store
		 */
		updateUsedTags(): void {
			const usedTagSet = new Set<string>();
			this.items.forEach((goal: Goal) => {
				if(goal.tags) {
					goal.tags.forEach((tag: string) => usedTagSet.add(tag));
				}
			});

			// Convert to array
			this.usedTags = Array.from(usedTagSet);

			// Sort on name
			this.usedTags.sort((a: string, b: string) => a.localeCompare(b));
		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		getMask() {
			return [
				'*',
				'tags.*',
			]
		}

	},

});
