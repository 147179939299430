<template>

	<template v-if="me?.isSuperAdmin()">
		<div class="flex flex-col">
			<SideBarMenuLink :to="'/users'" v-if="me?.hasAdminPermissions()">
				<UsersIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
				<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('User', 2) }}</span>
			</SideBarMenuLink>

			<SideBarMenuLink :to="'/schools'">
				<BuildingOffice2Icon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
				<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('School', 2) }}</span>
			</SideBarMenuLink>
		</div>
	</template>

	<div class="flex flex-col gap-6 pt-4">
		<div class="flex flex-col" v-if="!me?.isSuperAdmin()">
			<SideBarMenuLink :to="{ name: 'lesson-plan-library.list' }">
				<RectangleStackIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
				<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $t('My lesson plan library') }}</span>
			</SideBarMenuLink>
		</div>

		<div>
			<!-- School selector trigger -->
			<div class="py-4 border-y border-base-300 flex justify-between items-center"
				:class="[ iconsOnly? 'px-1' : 'px-2', multiOrgUser? 'hover:cursor-pointer hover:bg-base' : '']"
				@click="() => { if(multiOrgUser) { showSchoolSelector = true; } }"
			>
				<div class="flex flex-col uppercase text-xs font-bold overflow-hidden w-full">
					<div v-show="!iconsOnly" class="text-base-content-light">{{ $t('School') }}</div>
					<div v-show="iconsOnly" class="text-accent-content text-2xl text-center">
						{{ currentOrganisation.name.substring(0, 1)}}
					</div>
					<div v-show="!iconsOnly" class="overflow-hidden text-nowrap text-ellipsis text-accent-content">
						{{ currentOrganisation.name }}
					</div>
				</div>
				<div v-if="multiOrgUser" class="flex flex-col" :class="iconsOnly? 'mx-0 w-2' : 'mx-2 w-3'">
					<ChevronUpIcon class="w-full h-3" />
					<ChevronDownIcon class="w-full h-3" />
				</div>
			</div>

			<div class="flex flex-col mt-1" v-if="!me?.isSuperAdmin()">
				<SideBarMenuLink :to="'/users'" v-if="me?.hasAdminPermissions()">
					<UsersIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
					<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('User', 2) }}</span>
				</SideBarMenuLink>

				<SideBarMenuLink :to="'/classrooms'" v-if="me?.hasAdminPermissions()">
					<Squares2X2Icon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
					<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('Classroom', 2) }}</span>
				</SideBarMenuLink>

				<SideBarMenuLink to="/pupils" v-if="me?.hasAdminPermissions()">
					<UserGroupIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
					<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('Pupil', 2) }}</span>
				</SideBarMenuLink>

				<SideBarMenuLink to="/icon-libraries" v-if="me?.hasAdminPermissions()">
					<LanguageIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
					<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $t('Icon library') }}</span>
				</SideBarMenuLink>

				<SideBarMenuLink :to="{name: 'goals.list'}" v-if="showGoalsMenuItem">
					<MapPinIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
					<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('Goal',2) }}</span>
				</SideBarMenuLink>
			</div>
		</div>

		<template v-if="!me?.isSuperAdmin()">
			<div v-if="currentClassroom">

				<!-- Classroom selector trigger -->
				<div class="py-4 border-y border-base-300 flex justify-between items-center"
					:class="[ iconsOnly? 'px-1' : 'px-2', classrooms.length > 1 ? 'hover:cursor-pointer hover:bg-base' : '']"
					@click="() => { if(classrooms.length > 1) { showClassroomSelector = true; } }"
				>
					<div class="flex flex-col uppercase text-xs font-bold overflow-hidden w-full">
						<div v-show="!iconsOnly" class="text-base-content-light">{{ $t('Classroom') }}</div>
						<div v-show="iconsOnly" class="text-accent-content text-2xl text-center">
							{{ currentClassroom.name.substring(0, 1)}}
						</div>
						<div v-show="!iconsOnly" class="overflow-hidden text-nowrap text-ellipsis text-accent-content">
							{{ currentClassroom.name }}
						</div>
					</div>
					<div v-if="classrooms.length > 1" class="flex flex-col" :class="iconsOnly? 'mx-0 w-2' : 'mx-2 w-3'">
						<ChevronUpIcon class="w-full h-3" />
						<ChevronDownIcon class="w-full h-3" />
					</div>
				</div>

				<div class="flex flex-col mt-1">
					<SideBarMenuLink :to="'/classrooms/' + currentClassroom.id + '/pupils'">
						<UserGroupIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
						<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('Pupil', 2) }}</span>
					</SideBarMenuLink>

					<SideBarMenuLink :to="'/classrooms/' + currentClassroom.id + '/activities'">
						<RectangleGroupIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
						<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('Choice activity', 2) }}</span>
					</SideBarMenuLink>

					<SideBarMenuLink :to="'/classrooms/' + currentClassroom.id + '/agenda'">
						<CalendarDaysIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
						<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $t('Agenda') }}</span>
					</SideBarMenuLink>

					<SideBarMenuLink :to="'/classrooms/' + currentClassroom.id + '/observations'">
						<EyeIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
						<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $tc('Observation', 2) }}</span>
					</SideBarMenuLink>

					<SideBarMenuLink :to="'/classrooms/' + currentClassroom.id + '/insights'">
						<ChartBarIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
						<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $t('Insights') }}</span>
					</SideBarMenuLink>

					<SideBarMenuLink :to="'/classrooms/' + currentClassroom.id + '/icon-libraries'">
						<LanguageIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
						<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $t('Icon library') }}</span>
					</SideBarMenuLink>

					<SideBarMenuLink :to="{ name: 'classroom.activity-board-setup-info', params: { classroomId: currentClassroom.id } }">
						<ComputerDesktopIcon class="w6 h-6 @[10rem]/sidebar:mr-3"/>
						<span :class="[iconsOnly? 'hidden' : 'ml-3']">{{ $t('Activity board') }}</span>
					</SideBarMenuLink>
				</div>
			</div>

			<div v-else-if="classrooms.length === 0 && !iconsOnly" class="text-sm italic px-4">
				{{ $t('#notification: no classroom assigned yet') }}
			</div>
		</template>

	</div>

	<Modal v-if="showSchoolSelector" :modalTitle="$t('Select a school')" :closeOnClickOutside="true" width="md" @requestUnmount="showSchoolSelector = false">
		<template  #default="{ hide }">
			<template v-for="(role, index) in me.roles" :key="index">
				<button type="button"
					class="w-full px-2 py-3 flex justify-between text-left text-xs"
					:class="[ role.organisation === selectedSchool ? 'bg-base-100 border-l-4 border-accent' : role.organisation.active? 'hover:bg-base-100' : 'cursor-not-allowed' ]"
					@click="() => { hide(); changeSchool(role.organisation); }"
				>
					<span class="uppercase">{{ role.organisation.name }}</span>
					<span v-if="!role.organisation.active" class="badge">{{ $t('Inactive') }}</span>
				</button>
			</template>
		</template>
	</Modal>

	<Modal v-if="showClassroomSelector" :modalTitle="$t('Select a classroom')" :closeOnClickOutside="true" width="md" @requestUnmount="showClassroomSelector = false">
		<template  #default="{ hide }">
			<template v-for="(classroom, index) in classrooms" :key="index">
				<button type="button"
					class="w-full px-2 py-3 text-left text-xs"
					:class="[ classroom === selectedClassroom ? 'bg-base-100 border-l-4 border-accent' : 'hover:bg-base-100' ]"
					@click="() => { hide(); changeClassroom(classroom); }"
				>
					<span class="uppercase">{{ classroom.name }}</span>
				</button>
			</template>
		</template>
	</Modal>

</template>

<script lang="ts">
import SideBarMenuLink from './SideBarMenuLink.vue'
import {
	HomeIcon,
	UsersIcon,
	UserGroupIcon,
	BuildingOffice2Icon,
	LanguageIcon,
	RectangleGroupIcon,
	Squares2X2Icon,
	EyeIcon,
	ChartBarIcon,
	ComputerDesktopIcon,
	CalendarDaysIcon,
    RectangleStackIcon,
    MapPinIcon,
	ChevronUpIcon,
	ChevronDownIcon
} from '@heroicons/vue/24/outline'
import {useClassroomsStore} from "@/stores/Classrooms.store";
import {mapActions, mapState} from "pinia";
import {useOrganisationsStore} from "@/stores/Organisations.store";
import {useUsersStore} from "@/stores/Users.store";
import Modal from '@/components/ui/Modal.vue';
import { Organisation } from '@/models/Organisation.model';
import { Classroom } from '@/models/Classroom.model';

export default {
	name: 'SideBarMenu',
	components: {
		Modal,
		SideBarMenuLink,
		HomeIcon,
		UsersIcon,
		UserGroupIcon,
		BuildingOffice2Icon,
		LanguageIcon,
		RectangleGroupIcon,
		Squares2X2Icon,
		EyeIcon,
		ChartBarIcon,
		ComputerDesktopIcon,
		CalendarDaysIcon,
        RectangleStackIcon,
        MapPinIcon,
		ChevronUpIcon,
		ChevronDownIcon
	},
	props: {
		classrooms: {   // should look like [ { id: ..., name: ... }, { ... } ]
			type: Array,
			default: [],
		},
		iconsOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedClassroom: null,
			selectedSchool: null,
			showClassroomSelector: false,
			showSchoolSelector: false,
		};
	},

	created() {
		this.selectedClassroom = this.currentClassroom;
		this.selectedSchool = this.currentOrganisation;
	},

	computed: {
		...mapState(useClassroomsStore, ['currentClassroom']),
		...mapState(useOrganisationsStore, ['currentOrganisation']),
		...mapState(useUsersStore, ['me']),

		multiOrgUser() {
			return this.me.roles.length > 1;
		},

		showGoalsMenuItem(): boolean {
			if (!this.currentOrganisation.curriculum) {
				return false;
			}

			switch (this.currentOrganisation.curriculum.substring(0,4).toLowerCase()) {
				case 'zill':
				case 'ovsg':
					return false;

				default:
					return true;
			}
		}
	},

	methods: {
		...mapActions(useClassroomsStore, [ 'switchClassroom' ]),
		...mapActions(useOrganisationsStore, ['switchToOrganisation']),

		changeSchool(school: Organisation) {
			this.showSchoolSelector = false;
			if (!school.active || this.selectedSchool === school) {
				return;
			}
			this.selectedSchool = school;

			this.switchToOrganisation(school);
		},

		changeClassroom(classroom: Classroom) {
			this.showClassroomSelector = false;
			if (this.selectedClassroom === classroom) {
				return;
			}
			this.selectedClassroom = classroom;
			this.switchClassroom(classroom);
			//window.location.reload();
		}
	}
}
</script>
