<template>
<TheStdLayout>

	<template #pageheader>{{ $tc('School', 2) }}</template>

	<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

		<form @submit.prevent="handleSearchQuery" class="flex items-center justify-start w-full md:max-w-lg my-2 gap-3">
            <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex pt-2 md:pt-0 md:items-center pointer-events-none">
                    <MagnifyingGlassIcon class="w-5 h-5 text-base-content-light" />
                </div>
                <div class="flex flex-wrap -mx-3">
                    <div class="w-full px-3 md:mb-0">
                        <sms-input v-model="filter.searchQuery" name="search" minlength="3" type="search" class="pl-10 w-full md:w-56" />
                    </div>
                </div>
            </div>
            <div class="w-2/5 shrink-0">
                <sms-select v-model="filter.subscriptionPlan" name="plan" label="" class="capitalize w-40" :options="[
					{
						value: null,
						label: ''
					},
					{
						value: 'trial',
						label: $t('Trial')
					},
					{
						value: 'internal',
						label: $t('Internal')
					},
					{
						value: 'paying',
						label: $t('Paying')
					}
				]">
                </sms-select>
            </div>

			<div class="w-2/5 shrink-0">
				<sms-select v-model="filter.country" name="country" label="" class="capitalize w-40" :options="[
					{
						value: null,
						label: ''
					},
					...countries
				]">
				</sms-select>
			</div>

            <button type="submit" class="btn btn-primary">
							<span v-if="filtering" class="loading"></span>
							{{ $t('Search') }}
						</button>
            <button v-show="filterApplied" @click="handleFilterReset" type="button" class="button">{{ $t('Reset') }}</button>
        </form>

		<FloatingPlusButton @push="handleOrgCreateRequest" />

		<table class="divide-y divide-base-200 w-full mb-8">

			<thead>
				<tr>
					<th scope="col" class="table-col-title">
						<SortableHeader :order="organisationsStore.order" property="name" @change="organisationsStore.changeOrder">{{ $t('Name') }}</SortableHeader>
					</th>
					<th scope="col" class="hidden md:table-cell table-col-title">
						{{ $t('Subscription plan') }}
					</th>
					<th scope="col" class="hidden md:table-cell table-col-title">
						<SortableHeader :order="organisationsStore.order" property="account_id" @change="organisationsStore.changeOrder">{{ $t('Account id') }}</SortableHeader>
					</th>
					<th scope="col" class="hidden md:table-cell table-col-title">
						<SortableHeader :order="organisationsStore.order" property="country" @change="organisationsStore.changeOrder">{{ $t('Country') }}</SortableHeader>
					</th>
					<th scope="col" class="hidden md:table-cell table-col-title">
						<SortableHeader :order="organisationsStore.order" property="created_at" @change="organisationsStore.changeOrder">{{ $t('Created at') }}</SortableHeader>
					</th>
					<th scope="col" class="hidden md:table-cell table-col-title">
						<SortableHeader :order="organisationsStore.order" property="termination_date" @change="organisationsStore.changeOrder">{{ $t('Termination') }}</SortableHeader>
					</th>
					<th scope="col" class="px-6 py-3">
						<span class="sr-only">{{$t('Actions')}}</span>
					</th>
				</tr>
			</thead>

			<tbody class="bg-base divide-y divide-base-200">

				<tr v-show="!loaded">
					<td colspan="5">
						<Skeleton content-type="userlist" :items="3" class="m-4" thumbnail-classes="mask mask-squircle" />
					</td>
				</tr>

				<tr v-for="(org, key) in organisationsStore.organisations" :key="key">
    				<td class="px-4 py-3 whitespace-nowrap print:py-1">
						{{ org.name }}
						<div v-if="org.owner" class="text-xs text-base-content-light">
							{{ $t('Owned by') }}

							<button v-if="org.owner.id === this.usersStore.me.id" @click="switchToOrganisation(org)" type="button" class="hover:text-accent inline-flex gap-1 items-center" title="Impersonate">
								<span class="font-bold">{{ $t('You') }}</span>
								<ArrowTopRightOnSquareIcon class="w-4 h-4 inline-block" />
							</button>

							<button v-if="org.owner.id !== this.usersStore.me.id" @click="impersonate(org.owner.id, org.id)" type="button" class="hover:text-accent inline-flex gap-1 items-center" title="Impersonate">
								<span class="font-bold">{{  org.owner.getNameIdentifier() }}</span>
								<ArrowTopRightOnSquareIcon class="w-4 h-4 inline-block" />
							</button>
						</div>
						<div v-else class="text-sm text-danger">
							{{ $t('Warning') }} - {{ $t('No owner') }}!
						</div>
					</td>
					<td class="hidden md:table-cell table-col-title">
						<span class="badge border-none text-xs" :class="{ 'bg-green-400': org.subscription_plan === 'paying', 'bg-green-200': org.subscription_plan === 'trial' }">
							{{ org.subscription_plan }}
						</span>
					</td>
					<td class="hidden md:table-cell px-2 lg:px-6 py-2 whitespace-nowrap text-sm">
						{{ org.account_id }}
					</td>
					<td class="hidden md:table-cell px-2 lg:px-6 py-2 whitespace-nowrap text-sm">
						{{ org.country }}
					</td>
					<td class="hidden lg:table-cell px-2 lg:px-6 py-2 whitespace-nowrap text-sm">
						{{ localDate(org.created_at) }}
					</td>
					<td class="hidden lg:table-cell px-2 lg:px-6 py-2 whitespace-nowrap text-sm">
						<template v-if="org.termination_date">
							{{ terminationDate(org.termination_date) }}
						</template>
					</td>
    				<td class="px-4 py-3 whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2 sm:gap-4 print:py-1">
      					<div class="flex-row print:hidden">
							<button class="btn btn-ghost btn-circle bg-base-100" @click="handleOrgEditRequest(org)">
								<PencilIcon class="w-5 h-5"></PencilIcon>
							</button>
						</div>
					</td>

				</tr>

			</tbody>
		</table>

		<Pagination v-if="organisationsStore.currentPage" :page="organisationsStore.currentPage" @requestPage="loadPage"  />
  	</div>

	<OrganisationEditModal v-if="showOrgFormModal" :organisation="organisation" @requestClose="closeEditPopup" />

</TheStdLayout>
</template>


<script lang="ts">

import { mapActions, mapStores } from "pinia";
import {OrganisationFilter, useOrganisationsStore} from "@/stores/Organisations.store";

import OrganisationEditModal from "@/components/OrganisationEditModal.vue";
import Skeleton from '@/components/ui/Skeleton.vue';
import Pagination from "./ui/Pagination.vue";
import { ArrowTopRightOnSquareIcon, MagnifyingGlassIcon, PencilIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { useUsersStore } from "@/stores/Users.store";
import { PaginationState } from "@/models/PaginationState.model";
import { DateTime } from "luxon";
import SortableHeader from "@/components/ui/SortableHeader.vue";
import {SUPPORTED_COUNTRIES} from "@/i18n";

export default {

	data() {
		return {
			loaded: false,
			organisation: null,
			showOrgFormModal: false,
			filter: OrganisationFilter,
			filtering: false,
			filterApplied: false,
			currentResultsPage: 1,
		};
	},

	components: {
		SortableHeader,
		OrganisationEditModal,
		Skeleton,
		Pagination,
		PlusIcon,
		PencilIcon,
		ArrowTopRightOnSquareIcon,
		MagnifyingGlassIcon,
	},

	computed: {
		...mapStores(useOrganisationsStore, useUsersStore),

		localDate() {
			return (date: Date) => {
				return DateTime.fromJSDate(date).toLocaleString();
			};
		},

		countries() {
			let countries = [];
			for (const [key, value] of Object.entries(SUPPORTED_COUNTRIES)) {
				countries.push({
					value: key,
					label: value,
				});
			}
			return countries;
		}
	},

	beforeMount() {
		this.initializeFilter();
	},

	mounted() {

		// Check if we have access
		if (!this.usersStore.me.isSuperAdmin()) {
			this.$router.push({ name: 'user.list' });
			return;
		}

		this.loadOrganisations();
	},

	methods: {

		...mapActions(useUsersStore, ['impersonate']),
		...mapActions(useOrganisationsStore, ['switchToOrganisation']),

		terminationDate(date) {
			return DateTime.fromISO(date).toLocaleString();
		},

		handleOrgCreateRequest() {
			this.showOrgFormModal = true;
		},

		handleOrgEditRequest(org) {
			this.organisation = org;
			this.showOrgFormModal = true;
		},

		async loadOrganisations() {
			await this.organisationsStore.load(null, this.filter);
			this.loaded = true;
		},

		closeEditPopup() {
			this.organisation = null;
			this.showOrgFormModal = false;
		},

		initializeFilter() {
			this.filter = {
				subscriptionPlan: null,
				searchQuery: null
			};
		},

		async handleSearchQuery() {
			this.filterApplied = true;
			this.filtering = true;
			await this.loadOrganisations();
			this.filtering = false;
		},

		handleFilterReset() {
			this.initializeFilter();
			this.filterApplied = false;
			this.loadOrganisations();
		},

		loadPage(page: PaginationState) {
			this.organisationsStore.load(page, this.filter);
		},
	},

};

</script>
