import PKCE from 'js-pkce';
import appConfig from "@/config/app.config";
import axios from "axios";
import {useUsersStore} from "@/stores/Users.store";
import {GoogleDriveAccessToken} from "@/utils/GoogleDriveAccessToken.util";

/**
 * @TODO probably need to think about refreshing tokens at some point
 * @returns {Promise<unknown>}
 * @constructor
 */

export class Authentication {

	authenticated = false;

	accessTokenCallbacks = [];

	async authenticate() {

		if (this.authenticated) {
			return useUsersStore().me;
		}

		await this.requireAccessToken();
		try {
			const user = await useUsersStore().loadMe();
			if (user.locale) {
				let originalLocalStorage = localStorage.getItem('locale');
				localStorage.setItem('locale', user.locale);
				if (originalLocalStorage !== user.locale) {
					window.location.reload();
				}
			}

			return user;

		} catch (e) {
			return null;
		}
	}

	hasAccessToken() {
		return localStorage.getItem('access_token') !== null;
	}

	onAccessToken(callback) {
		this.accessTokenCallbacks.push(callback);
	}

	async requireAccessToken() {
		return new Promise((resolve, reject) => {

			// look for access token
			if (localStorage.getItem('access_token')) {
				resolve();
				return;
			}

			const pkce = new PKCE({
				client_id: appConfig.OAUTH.client_id,
				redirect_uri: appConfig.OAUTH.redirect_uri,
				authorization_endpoint: appConfig.OAUTH.authorization_endpoint,
				token_endpoint: appConfig.OAUTH.token_endpoint,
				requested_scopes: appConfig.OAUTH.requested_scopes,
				storage: localStorage
			});

			const urlParams = new URLSearchParams(window.location.search);
			if (urlParams.has('error')) {
				alert('Authentication error: ' + urlParams.get('error'));
				return;
			}

			// Redirect to login
			if (!urlParams.has('code')) {
				window.location.replace(pkce.authorizeUrl({ prompt: 'login' }));
				return;
			}

			const url = window.location.href;
			pkce.exchangeForAccessToken(url).then((resp) => {
				const token = resp.access_token;
				localStorage.setItem('access_token', token);

				this.accessTokenCallbacks.forEach((callback) => {
					callback(token);
				});

				// Remove code from url
				window.history.replaceState({}, document.title, '/');

				resolve();
			});
		});
	}

	async logout() {

		await axios.post(appConfig.API_URL + 'logout', {}, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token')
			}
		});

		this.clearAccessToken(true);
	}

	clearAccessToken(refresh = false) {
		localStorage.removeItem('access_token');
		localStorage.removeItem('organisation_id');

		// Also remove any items that we need for filepicker
		GoogleDriveAccessToken.clear();

		if (refresh) {
			window.location = '/';
		}
	}

}

const auth = new Authentication();

export { auth };
