<template>

<Teleport to="body">
<div class="relative z-40 drawer isolate" role="dialog" aria-modal="true" ref="drawer">

	<div v-if="mounted && withBackdrop" class="fixed inset-0 bg-black transition-opacity ease-in-out duration-300" :class="[state == 'show'? 'opacity-20' : 'opacity-0']"></div>

	<div class="fixed inset-0 overflow-hidden">
		<div class="absolute inset-0 overflow-hidden" @keyup.esc="prepareToClose" @click.self="handleClickOutside">
			<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-4 sm:pl-10">

				<div v-if="mounted" class="pointer-events-auto w-screen transition-all ease-in-out duration-300 panel-wrapper" :class="[drawerWidth, state == 'show'? 'translate-x-0' : 'translate-x-full']">
					<div class="relative flex h-full flex-col overflow-y-scroll bg-white shadow-xl border-l">
						<!-- HEADER -->
						<header class="px-4 sm:pl-6 pt-5 pb-4 bg-base-100 sticky top-0 z-10">
							<div class="flex items-start justify-between">
								<slot name="header">
									<div class="font-semibold text-md">
										{{ title }}
									</div>
								</slot>

								<button v-if="showDefaultCloseButton" type="button" @click="prepareToClose" class="btn btn-sm btn-ghost btn-circle">
									<span class="sr-only">Close panel</span>
									<XMarkIcon class="w-6 h-6"></XMarkIcon>
								</button>
							</div>
						</header>
						<!-- CONTENT -->
						<main class="flex-grow overflow-y-auto overflow-x-hidden flex flex-col">
							<slot :hide="prepareToClose" :resizeTo="resizeTo">
								Dialog content
							</slot>
						</main>
						<!-- FOOTER -->
						<!-- use class="drawer-action-bar" in the main slot -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</Teleport>

</template>


<style>
.drawer + .drawer .panel-wrapper {
	@apply pl-4;
}
.drawer-content {
	@apply px-4 sm:px-6
}
.drawer-action-bar {
	@apply absolute bottom-0 py-4 px-4 sm:px-6 inset-x-0 border-t border-base-200 shrink-0 flex bg-white/50 backdrop-blur-sm z-10;
}
.drawer main:has(.drawer-action-bar) {
	@apply pb-24;
}
</style>


<script lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';


export default {

	emits: ['requestUnmount'],

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			default: '',
		},
		initialWidth: {
			type: String,
			default: 'md',
			validator(value) {
				return ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl'].includes(value);
			},
		},
		withBackdrop: {
			type: Boolean,
			default: true,
		},
		showDefaultCloseButton: {
            type: Boolean,
            default: true,
        },
		beforeClose: {
			type: Function,
			default: null,
		},
		closeOnClickOutside: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		XMarkIcon,
	},

	data() {
		return {
			opened: false,
			mounted: false,
			state: 'hide',
			width: '',
		};
	},

	computed: {
		drawerWidth() {
			switch (this.width) {
				case 'xs':
					return 'sm:max-w-xs';
				case 'sm':
					return 'sm:max-w-sm';
				case 'md':
					return 'sm:max-w-md';
				case 'lg':
					return 'sm:max-w-lg';
				case 'xl':
					return 'sm:max-w-xl';
				case '2xl':
					return 'sm:max-w-2xl';
				case '3xl':
					return 'sm:max-w-3xl';
				case '4xl':
					return 'sm:max-w-4xl';
				case '5xl':
					return 'sm:max-w-5xl';
				case 'full':
					return 'sm:max-w-full';
			}
		}
	},

	beforeMount() {
		this.width = this.initialWidth;
	},

	mounted() {
		this.mounted = true;

		// https://stackoverflow.com/questions/47634258/what-is-nexttick-and-what-does-it-do-in-vue-js
		setTimeout(() => {
			this.state = 'show';
			const autoFocusElement = this.$refs.drawer.querySelector('[autofocus]');
    		// If found, apply focus to it
    		if (autoFocusElement) {
      			autoFocusElement.focus();
    		}
		}, 0);
	},

	methods: {
		async prepareToClose() {
			if (this.beforeClose) {
				let canClose = await this.beforeClose();
				if (!canClose) {
					return;
				}
			}

			this.state = 'hide';
			setTimeout(() => {  // allow animation to complete before emitting event
				this.$emit('requestUnmount');
			}, 300);    // should correspond with the 'duration' property of the CSS transition
		},

		handleClickOutside() {
			if (this.closeOnClickOutside) {
				this.prepareToClose();
			}
		},

		resizeTo(width: string) {
			this.width = width;
		}
	}
}
</script>
