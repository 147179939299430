import { defineStore } from 'pinia'
import {orgApi} from "@/utils/Api.util";
import {IconLibrary} from "@/models/IconLibrary.model";
import {ErrorMessage} from "@/stores/errors/ErrorMessage";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import { OrderDirection, OrderParameter } from "@/models/OrderParameter.model";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import { Pupil } from "@/models/Pupil.model";

interface IconLibraryStoreState {
	emptyIconLibrary ?: IconLibrary,
	iconLibraries: IconLibrary[],
	errorMessage: ErrorMessage | null,
	loadedClassroomId: string | null,
	order: OrderParameter
}

export const useIconLibrariesStore = defineStore('iconLibraries', {
	state: (): IconLibraryStoreState => ({
		iconLibraries: [],
		errorMessage: null,
		loadedClassroomId: null,
		order: useDefaultOrderStore().iconLibrariesOrder
	}),

	getters: {

	},

	actions: {

		/**
		 * Load all icon libraries, if not already loaded
		 */
		async load(classroomId: string | null) {

			// already loaded (for this classroom)? Skip!
			if (this.iconLibraries.length > 0 && this.loadedClassroomId === classroomId) {
				return;
			}

			this.iconLibraries = [];
			if (classroomId) {
				await this.getIconLibrariesForClassroom(classroomId);
			} else {
				await this.getIconLibraries();
			}

			this.orderBy(this.order);

		},

		changeOrder(order: OrderParameter) {
			this.order = order;
			useDefaultOrderStore().setDefaultIconLibrariesOrder(order);
			this.orderBy(this.order);
		},

		orderBy(orderBy: OrderParameter) {
			this.sortItemsBy(orderBy.property, orderBy.direction);
		},

		/**
		 * Create a new empty IconLibrary
		 * @param forClassroom
		 */
		new(forClassroom = false) {

			const emptyIconLibrary = new IconLibrary();
			emptyIconLibrary.name = '';
			emptyIconLibrary.group = '';

			if (forClassroom) {
				// check if we have a selected classroom
				if (!useClassroomsStore().currentClassroom) {
					throw new Error('Could not create new library for classroom as no classroom is set.');
				}

				emptyIconLibrary.classroomId = useClassroomsStore().currentClassroom.id.toString();
			}

			return emptyIconLibrary;

		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		/**
		 * Load all icon libraries
		 */
		async getIconLibraries() {

			if (this.loadedClassroomId !== null) {
				this.iconLibraries = [];
			}

			const response = await orgApi.get("iconlibraries?mask=id,name,group,classroom.*");
			this.iconLibraries = response.data.data.map(IconLibrary.mapFromServer);
			this.loadedClassroomId = null;
		},

		async getIconLibrariesForClassroom(classroomId: string) {

			if (this.loadedClassroomId !== classroomId) {
				this.iconLibraries = [];
			}

			const response = await orgApi.get("iconlibraries?mask=id,name,group,classroom.*", {
				params: {
					classroomIds: classroomId
				}
			});

			this.iconLibraries = response.data.data.map(IconLibrary.mapFromServer);
			this.loadedClassroomId = classroomId;
		},

		async getFromId(iconLibraryId: string) {

			const response = await orgApi.get("iconlibraries/" + iconLibraryId + "?mask=id,name,group,classroom.*", {});

			const iconLibrary = IconLibrary.mapFromServer(response.data.data);

			this.iconLibraries = [ iconLibrary ];
			return this.iconLibraries[0];
		},

		/**
		 * @param library
		 */
		async save(library: IconLibrary) {

			this.clearErrorMessage();

			if(library.id) {
				// update existing library
				try {
					const response = await orgApi.put(
						'iconlibraries/' + library.id,
						{
							name: library.name
						}
					);
					library.setFromServerData(response.data.data);

					var storeIndex = this.iconLibraries.findIndex(
						storeItem => storeItem.id == library.id
					);
					this.iconLibraries[storeIndex] = library.clone();

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}
			else {
				// create new library
				try {
					const response = await orgApi.post('iconlibraries', library.getServerData());

					library.setFromServerData(response.data.data);
					this.iconLibraries.push(library);

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}

		},

		/**
		 * Remove an iconLibrary
		 * @param iconLibrary
		 */
		async delete(iconLibrary: IconLibrary) {

			try {
				await orgApi.delete("iconlibraries/" + iconLibrary.id);

				const index = this.iconLibraries.findIndex(v => v.id === iconLibrary.id);
				if (index >= 0) {
					this.iconLibraries.splice(index, 1);
				}
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		sortItemsBy(attr: string, direction = OrderDirection.ASC) {
			// Sort on name (for now)
			this.iconLibraries.sort(
				(a: IconLibrary, b: IconLibrary) => {
					let result: number;
					if(!a[attr] && !b[attr]) {
						result = 0;
					} else if(!a[attr]) {
						result = 1;
					} else if(!b[attr]) {
						result = -1;
					} else {
						result = a[attr].localeCompare(b[attr]);
					}

					if(direction === OrderDirection.DESC) {
						result = -result;
					}
					return result;
				}
			);
		}
	},


})
