import {defineStore} from 'pinia'
import {Activity} from "@/models/Activity.model";
import {orgApi} from "@/utils/Api.util";
import { Icon } from "@/models/Icon.model";
import { Symbol } from "@/models/Symbol.model";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import {ErrorMessage} from "@/stores/errors/ErrorMessage";
import {useClassroomsStore} from "@/stores/Classrooms.store";

const activityMask: string[] = [
	'id',
	'name',
	'seats',
	'color',
	'symbol',
	'icon.*',
	'icon.library.id'
];

interface ActivityStoreState {
	errorMessage: ErrorMessage | null,
	activities: Activity[],
	loadedClassroomId: string | null,
	activityNamesForAllMyClassrooms: string[]
}

export const useActivitiesStore = defineStore('activities', {

	state: (): ActivityStoreState => ({
		errorMessage: null,
		activities: [],
		loadedClassroomId: null,
		activityNamesForAllMyClassrooms: []
	}),

	actions: {

		new() {
			let newActivity = new Activity();
			newActivity.classroomId = useClassroomsStore().currentClassroom.id.toString();
			newActivity.seats = 2;	// default seats number

			return newActivity;
		},

		async load() {

			const classroomId = useClassroomsStore().currentClassroom.id;

			// Only load when not already loaded
			if (this.activities.length > 0 && this.loadedClassroomId === classroomId) {
				return;
			}

			let params: any = {};

			params.classroomIds = useClassroomsStore().currentClassroom.id;
			params.mask = activityMask.join(',');

			this.loadedClassroomId = classroomId;
			const response = await orgApi.get('activities', {
				params: params
			});

			this.activities = response.data.data.map(Activity.mapFromServer);

			this.sortItemsBy('name');
		},

		async getActivityNames(currentClassroomOnly: boolean = true) {

			if(currentClassroomOnly) {
				await this.load();
				return this.activities.map(item => item.name);
			} else {

				if(this.activityNamesForAllMyClassrooms.length > 0) {
					return this.activityNamesForAllMyClassrooms;
				}

				const classroomIds = useClassroomsStore().classrooms.map(cr => cr.id);

				const response = await orgApi.get('activities', {
					params: {
						classroomIds: classroomIds.join(','),
						mask: 'name'
					}
				});

				this.activityNamesForAllMyClassrooms = response.data.data.map(item => item.name);
				return this.activityNamesForAllMyClassrooms;
			}
		},

		findById(id: string) {
			return this.activities.find(p => p.id === id);
		},

		findByIds(ids: string[]) {
			return this.activities.filter(p => ids.indexOf(p.id) !== -1)
		},

		async save(activity: Activity) {

			this.errorMessage = null;

			if(activity.id) {
				// update existing pupil
				try {
					const response = await orgApi.put('activities/' + activity.id, activity.getServerData());
					this.updateStoreItem(activity);
				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			} else {
				// create new pupil
				try {
					const response = await orgApi.post('activities', activity.getServerData());
					activity.id = response.data.data.id;
					this.addStoreItem(activity);
				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}

			this.sortItemsBy('name');
		},

		async delete(activity: Activity) {

			this.errorMessage = null;
			try {
				const response = await orgApi.delete('activities/' + activity.id);
				this.deleteStoreItem(activity);
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		updateStoreItem(activity: Activity) {
			const storeIndex = this.activities.findIndex(
				storeItem => storeItem.id == activity.id
			);
			this.activities[storeIndex] = activity.clone();
		},

		addStoreItem(activity: Activity) {
			this.activities.push(activity);
		},

		deleteStoreItem(activity: Activity) {
			const index = this.activities.findIndex(v => v.id === activity.id);
			if (index >= 0) {
				this.activities.splice(index, 1);
			}
		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		setActivityCustomIcon(activity: Activity, icon: Icon) {
			activity.icon = icon;
			activity.symbol = null;
		},

		setActivityStandardIcon(activity: Activity, symbol: Symbol) {
			activity.symbol = symbol;
			activity.icon = null;
		},

		sortItemsBy(attr) {
			// Sort on name (for now)
			this.activities.sort(
				(a: Activity, b: Activity) => {
					return a[attr].localeCompare(b[attr]);
				}
			);
		},

		getFromId(id: string): Activity | null {
			return this.pupils.find(
				storeItem => storeItem.id == id
			);
		}

	}

});
