<template>

    <div class="avatar aspect-square">
		<div class="mask mask-squircle items-center justify-center"
			:class="['bg-' + color + '-400', disabled ? 'opacity-25' : '']"
			style="display: flex;"
		>
			<p class="mask mask-squircle aspect-square flex items-center justify-center"
				:class="['text-' + color + '-500', 'bg-' + color + '-50', withBorder? 'w-11/12' : 'w-12/12']" >
				<img :src="this.icon.url" />
			</p>
		</div>
	</div>

</template>

<script lang="ts">

import { Icon } from "@/models/Icon.model";

export default {
    props: {
        icon: {
            type: Icon,
            required: true
        },
        color: {
            type: String,
            default: 'gray'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        withBorder: {
			type: Boolean,
			default: true
		}
    }
}
</script>