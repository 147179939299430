import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';
import { auth } from "@/auth";
import {hasAccessToken} from "@/utils/Api.util";
import {useOrganisationsStore} from "@/stores/Organisations.store";
import {ReloadScheduler} from "@/utils/ReloadScheduler.util";
import { connectToPusher } from "@/connectToPusher.js";

const AB_MAX_LIFETIME_BEFORE_RELOADS = 22 * 60 * 60 * 1000;

export function setupRouter() {
	const router = createRouter({
		history: createWebHistory(),
		routes,
	});

	/**
	 * Filter routes based on permissions
	 */
	router.beforeEach(async (to, from) => {

		// Should we connect to pusher?
		if (to.meta.pusherConnect) {
			// Activity board also wants to connect to pusher
			connectToPusher();
		}

		// Are we loading the activity board?
		if (
			typeof (to.meta.activityBoardConnect) !== 'undefined' &&
			to.meta.activityBoardConnect == true
		) {
			ReloadScheduler.scheduleReload('activityBoard', AB_MAX_LIFETIME_BEFORE_RELOADS);

			// Now continue the regular auth flow.
			if (hasAccessToken()) {
				return true;
			}

			return {
				name: 'activity-board-select'
			};
		}

		// Do we require guest access?
		if (typeof (to.meta.guestAccess) !== 'undefined' && to.meta.guestAccess == true) {
			ReloadScheduler.scheduleReload('activityBoard', AB_MAX_LIFETIME_BEFORE_RELOADS);
			return true;
		}

		const user = await auth.authenticate();
		if (!user) {
			return {
				name: 'network-error'
			}
		}

		// Should we select an organisation?
		if (to.name === 'organisation.select') {
			return true;
		}

		// Do we have an organisation?
		if (
			!useOrganisationsStore().currentOrganisation &&
			user.roles.length > 0
		) {
			return {
				name: 'organisation.select'
			};
		}

		// Do we have a classroom id?
		if (typeof (to.params.classroomId) !== 'undefined') {
			// Does user have access to this classroom?
			const organisation = useOrganisationsStore().currentOrganisation;
			if (
				organisation.classrooms.findIndex(classroom => classroom.id == to.params.classroomId) < 0
			) {
				return {
					name: 'home'
				};
			}
		}

		// Do we have the required permissions?
		if (typeof (to.meta.permissions) !== 'undefined') {
			const permissions = to.meta.permissions.split(',');
			let hasPermission = true;
			for (let i = 0; i < permissions.length; i++) {
				switch (permissions[i]) {
					case 'admin':
						if (!user.hasAdminPermissions()) {
							hasPermission = false;
						}
						break;

					case 'superadmin':
						if (!user.isSuperAdmin()) {
							hasPermission = false;
						}
						break;

					case 'teacher':
						if (!user.hasTeacherPermissions()) {
							hasPermission = false;
						}
						break;

					default:
						alert('Unknown permission: ' + permissions[i]);
						hasPermission = false;
						break;
				}
			}

			if (!hasPermission) {
				return {
					name: 'home'
				};
			}
		}
	});

	return router;
}
