<template>

	<div id="lessonPlanForm">
		<!-- TAB MENU -->
		<div class="tabs justify-center mb-6 flex-nowrap">
			<a class="tab tab-lg tab-bordered leading-5" :class="[
				activeTab === 'content' ? 'tab-active' : '',
				invalidTitleError ? 'text-red-500' : ''
			]" @click.prevent="activeTab = 'content'">
				{{ $t('Content') }}
			</a>

			<a class="tab tab-lg tab-bordered leading-5" :class="[activeTab === 'supplies'? 'tab-active' : '']" @click.prevent="activeTab = 'supplies'">
				{{ $t('Supplies') }}
				<span class="badge badge-sm ml-1 badge-info" v-if="lessonPlan?.supplies?.length > 0">{{ lessonPlan.supplies.length }}</span>
			</a>

			<a class="tab tab-lg tab-bordered leading-5" :class="[activeTab === 'goals'? 'tab-active' : '']" @click.prevent="openGoalsTab">
				{{ $tc('Goal', 2) }}
				<span class="badge badge-sm ml-1 badge-info" v-if="lessonPlan.goals?.length > 0">{{ lessonPlan.goals.length }}</span>
			</a>
			<a class="tab tab-lg tab-bordered leading-5" :class="[activeTab === 'attachments'? 'tab-active' : '']" @click.prevent="activeTab = 'attachments'">{{ $tc('Attachments', 2) }}
				<span class="badge badge-sn ml-1 badge-info" v-if="lessonPlan?.attachments?.length > 0">{{ lessonPlan.attachments.length }}</span>
			</a>
		</div>

		<!-- TAB PANELS -->
		<div class="grid">
			<!-- CONTENT TAB -->
			<div class="flex flex-col gap-6" style="grid-area: 1 / 1 / 2 / 2;" :class="[activeTab === 'content'? 'visible' : 'invisible']">

				<div
					class="overflow-hidden font-medium rounded focus-within:border focus-within:ring-accent focus-within:ring-2"
					:class="[readOnly? '' : 'border bg-base-100']"
				>
					<input
						class="w-full text-lg bg-transparent border-0 focus:ring-0 focus:outline-none px-4 py-2"
						:class="[ readOnly? '' : 'border-b', invalidTitleError? 'border-red-500' : '']"
						maxlength="128" name="title" id="title" :placeholder="$t('Title')" autofocus
						ref="titleField"
						v-model="lessonPlan.title"
						:disabled="readOnly"
					/>
					<TextEditor
						class="instruction-editor min-h-[12rem]"
						editClasses="text-sm bg-transparent px-4 py-2 leading-5 border-0 focus:ring-0 focus:outline-none"
						viewClasses="text-sm px-4 py-2 leading-5"
						readOnlyClasses="text-sm px-4 py-2 leading-5"
						:placeholder="$t('Description') + '...'"
						:readOnly="readOnly"
						v-model="lessonPlan.instructions"
					/>
				</div>

				<div v-if="showOptionGuided" class="flex items-center gap-2">
					<input type="checkbox" class="checkbox checkbox-sm" v-model.lazy="lessonPlan.isGuided" name="guided" id="guided-activity" :disabled="readOnly" />
					<label for="guided-activity" class="flex gap-2">
						<span class="text-sm mt-0.5">{{ $t('Under guidance of the teacher') }}</span>
						<LifebuoyIcon class="w-6 h-6" />
					</label>
				</div>

				<div>
					<label class="text-xs font-bold">{{ $tc('Theme', 2 )}}</label>
					<p v-if="readOnly && !(lessonPlan.themeTags && lessonPlan.themeTags.length)" class="text-base-content-light text-sm italic py-2">
						{{ $t('None') }}
					</p>
					<TagInput
						v-else
						v-model="lessonPlan.themeTags"
						tagClasses="tag-theme"
						:tagPool="this.tagsStore.getByType('theme')"
						:readOnly="readOnly"
						class="grow"
					/>
				</div>

				<div>
					<label class="text-xs font-bold">{{ $tc('Tag', 2) }}</label>
					<p v-if="readOnly && !(lessonPlan.activityTags && lessonPlan.activityTags.length)" class="text-base-content-light text-sm italic py-2">
						{{ $t('None') }}
					</p>
					<TagInput
						v-else
						v-model="lessonPlan.activityTags"
						tagClasses="tag-activity"
						:tagPool="generalTagPool"
						:readOnly="readOnly"
						class="grow"
					/>
				</div>

				<!--
				<div class="">
					<label class="text-xs font-bold">Deelname</label>
					<div class="mt-1 flex flex-col gap-y-2">
						<span class="flex items-center gap-x-2">
							<input type="radio" id="individual" name="activity-type" class="radio" />
							<label for="individual">Individueel uit te voeren</label>
						</span>
						<span class="flex items-center gap-x-2">
							<input type="radio" id="in-group" name="activity-type" class="radio" @change="" />
							<label for="in-group">In groep uit te voeren</label>
						</span>
					</div>
				</div>
				-->

			</div>

			<!-- SUPPLIES TAB -->
			<div class="px-6" style="grid-area: 1 / 1 / 2 / 2;" :class="[activeTab === 'supplies'? 'visible' : 'invisible']">

				<p v-if="readOnly && lessonPlan.supplies.length === 0" class="text-base-content-light text-sm italic text-center">
					{{ $t('No supplies') }}
				</p>

				<ListEditor v-model="lessonPlan.supplies" :readOnly="readOnly" />
			</div>

			<!-- GOALS TAB -->
			<div style="grid-area: 1 / 1 / 2 / 2;" :class="[activeTab === 'goals'? 'visible' : 'invisible']">

				<template v-if="!organisationsStore.currentOrganisation.curriculum">

					<p class="text-base-content-light pt-4 italic text-center">{{ $t('No goals available yet') }}</p>

				</template>

				<template v-else>

					<div class="text-right">
						<button v-if="!readOnly" type="button" class="btn btn-primary btn-circle" @click="showGoalSelector = true">
							<PlusIcon class="w-5 h-5"></PlusIcon>
						</button>
					</div>

					<div v-show="!lessonPlan.goals?.length" class="text-base-content-light pt-4 italic text-center mb-6">
						{{ $t('No goals selected' )}}
					</div>

					<ul class="flex flex-col gap-4 py-6">
						<li v-for="goal in sortedLessonPlanGoals" :key="goal.id" class="flex gap-2" :class="[goal.isSubgoal()? 'pl-6 text-sm' : '']">
							<MapPinIcon class="shrink-0" :class="[goal.isSubgoal()? 'mt-1 w-3 h-3' : 'mt-0.5 w-4 h-4']" :style="{ color: goal.color ?? '#86efac' }" />
							<span class="text-base-content-light mr-2">{{ goal.fullPath }}</span>
							<GoalTitle :goal="goal" titleClasses="whitespace-pre-wrap" />
							<div class="grow text-right pl-4" v-if="!readOnly">
								<button type="button" class="btn btn-sm btn-ghost btn-circle bg-base-100" @click="removeGoal(goal)">
									<TrashIcon class="w-4 h-4"></TrashIcon>
								</button>
							</div>
						</li>
					</ul>

				</template>

			</div>

			<!-- ATTACHMENTS TAB -->
			<div class="" style="grid-area: 1 / 1 / 2 / 2;" :class="[activeTab === 'attachments'? 'visible' : 'invisible']">

				<Dropzone @files-dropped="filesDropped($event)" class="h-full">

					<Attachments
						v-model="this.lessonPlan.attachments"
						v-model:uploading="attachmentsBusy"
						:preview="false"
						ref="attachmentsContainer"
						accept="*"
						:header="false"
						:uploadButton="true"
						:externalFilesButton="true"
						:readOnly="readOnly"
					/>

				</Dropzone>

			</div>

		</div>

		<GoalSelector v-if="!readOnly && showGoalSelector"
			@requestUnmount="showGoalSelector = false"
			:curriculum="organisationsStore.currentOrganisation.curriculum"
			:selectedGoals="lessonPlan.goals"
			:suggestedGoals="suggestedGoals"
			@goalSelected="addGoal"
			@goalUnselected="removeGoal"
		/>

	</div>

</template>

<style>
.instruction-editor a {
	text-decoration: underline;
}
</style>


<script lang="ts">
import { CheckIcon, LifebuoyIcon, TrashIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { MapPinIcon } from '@heroicons/vue/24/solid';
import { LessonPlan } from "@/models/LessonPlan.model";
import ListEditor from '@/components/ui/ListEditor.vue';
import TextEditor from '@/components/ui/TextEditor.vue';
import TagInput from '@/components/ui/TagInput.vue';
import { useTagsStore } from "@/stores/Tags.store";
import { mapStores, mapState } from "pinia";
import {useOrganisationsStore} from "@/stores/Organisations.store";
import { useUsersStore } from "@/stores/Users.store";
import { useActivitiesStore } from '@/stores/Activities.store';
import { Tag as TagModel } from "@/models/Tag.model";
import Attachments from "@/components/Attachments.vue";
import Dropzone from "@/components/Dropzone.vue";
import GoalTitle from '@/components/GoalTitle.vue';
import GoalSelector from "@/components/GoalSelector.vue";
import {Goal} from "@/models/Goal.model";

export default {

	emits: [ 'form:close' ],

	props: {
		lessonPlan: {
			type: LessonPlan,
			required: true,
		},
		showOptionGuided: {
			type: Boolean,
			default: false,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		classroomContext: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Object,
			default: () => ({}),
		},
		suggestedGoals: {
			type: Array,
			default: () => [],
		},
	},

	components: {
		Dropzone,
		Attachments,
		CheckIcon, LifebuoyIcon, TrashIcon, PlusIcon,
		MapPinIcon,
		ListEditor,
		TextEditor,
		TagInput,
		GoalTitle,
		GoalSelector,
	},

	data() {
		return {
            activeTab: 'content',
			attachmentsBusy: false,
			contextActivityNames: [],
			showGoalSelector: false,
		}
	},

	computed: {
		...mapStores(useTagsStore, useActivitiesStore, useOrganisationsStore),
		...mapState(useUsersStore, ['me']),

		invalidTitleError() {
			if (this.errors?.fields?.title && !this.lessonPlan.title) {
				return true;
			}
			return false;
		},

		generalTagPool() {
			const existingTagNames = this.tagsStore.getByType('activity').map(tag => tag.name);

			let tagNames = existingTagNames;
			this.contextActivityNames.forEach((name) => {
				if (!tagNames.some(tagName => tagName.toLowerCase() === name.toLowerCase())) {
					tagNames.push(name);
				}
			});

			// sort by name
			tagNames.sort((a, b) => a.localeCompare(b));

			// convert to Tags
			const tagPool = [];
			tagNames.forEach((name) => {
				const tag = new TagModel();
				tag.name = name;
				tag.type = 'activity';
				tagPool.push(tag);
			})
			return tagPool;
		},

		sortedLessonPlanGoals() {
			return this.lessonPlan.goals.sort((a, b) => a.fullPath.localeCompare(b.fullPath));
		},
	},

	async mounted() {
		await this.tagsStore.load();
		this.contextActivityNames = await this.activitiesStore.getActivityNames(this.classroomContext);
	},

	watch: {
		readOnly: {
			handler(newValue, oldValue) {
				if(oldValue === true && newValue === false) {
					if (this.activeTab === 'content' && !this.lessonPlan.title) {
						setTimeout(() => {
							this.$refs.titleField.focus();
						}, 100);
					}
				}
			},
		},

		errors: {
			handler(newValue, oldValue) {
				if(newValue && newValue.fields && newValue.fields.title) {
					this.activeTab = 'content';
				}
			},
			deep: true,
		},
	},

	methods: {
		close() {
			this.$emit('form:close');
		},

		filesDropped(files) {
			this.$refs.attachmentsContainer.filesDropped(files);
		},

		openGoalsTab() {
			this.activeTab = 'goals';
		},

		addGoal(goal: Goal) {
			// avoid adding duplicates
			const goalAlreadyAdded = this.lessonPlan.goals.some(
				(item) => item.equals(goal)
			);
			if(!goalAlreadyAdded) {
				this.lessonPlan.goals.push(goal);
			}
		},

		removeGoal(goal: Goal) {
			// in case of a 'main goal', also remove any potential 'sub goals'
			if(goal.sourcePathId) {
				this.lessonPlan.goals = this.lessonPlan.goals.filter(
					(item) => item.sourceParentPath != goal.fullPath
				)
			}

			this.lessonPlan.goals = this.lessonPlan.goals.filter(
				(item) => !item.equals(goal)
			);
		},
	}
}
</script>
