<template>
	<div>
		<div class="flex gap-2 items-start">
			<button type="button" v-if="collapsable" class="btn btn-sm btn-ghost shrink-0" @click="filterPanelOpen = !filterPanelOpen">
				<FunnelIcon class="w-5 h-5"/>
				{{ $t('Filter')  }}
				<ChevronDownIcon class="w-4 h-4" :class="[filterPanelOpen? 'rotate-180' : '']" />
			</button>
			<div v-else class="flex items-center gap-2 px-4 py-1.5 uppercase text-sm font-semibold">
				<FunnelIcon class="w-5 h-5"/>
				{{ $t('Filter')  }}
			</div>
			<button v-show="filter.valueCount() > 0" type="button" class="btn btn-sm btn-ghost bg-base-100" @click="resetFilter">
				{{ $t('Reset') }}
			</button>
			<div id="filter-labels" class="flex flex-wrap items-start gap-2 mt-0.5">
				<Tag v-for="filterVal in filter.getParamValues('activityLabels')" :key="filterVal.id" class="tag-activity" :removeable="true" @remove="removeFilterValue('activityLabels', filterVal)">
					{{ filterVal.label }}
				</Tag>
				<Tag v-for="filterVal in filter.getParamValues('themeLabels')" :key="filterVal.id" class="tag-theme" :removeable="true" @remove="removeFilterValue('themeLabels', filterVal)">
					{{ filterVal.label }}
				</Tag>
				<Tag v-for="filterVal in filter.getParamValues('keywords')" :key="filterVal.id" :removeable="true" class="tag-keyword tag-with-icon" @remove="removeFilterValue('keywords', filterVal)">
					{{ filterVal.label }}
				</Tag>
			</div>
		</div>

		<div v-show="!collapsable || filterPanelOpen" class="@container bg-base grid grid-cols-12 gap-3 my-3">

			<!-- <div class="px-4 text-right">
				<template v-if="resultCounter !== null">
					<span v-show="resultCounter === -1" class="loading loading-spinner"></span>
					<span v-show="resultCounter > -1" class="text-sm">{{ resultCounter }} {{ $tc('result', resultCounter) }}</span>
				</template>
			</div> -->

			<div class="col-span-12 @xl:col-span-4 @5xl:col-span-3">
				<MultiSelect class="w-full"
					:values="activityTagFilters"
					:selectedIds="filter.getParamValues('activityLabels').map(label => label.id)"
					:collapsable="true"
					dropdownMaxHeight="17rem"
					buttonFieldClasses="input-sm"
					inputFieldClasses="input-sm input-bordered bg-base"
					@itemSelected="addFilterValue('activityLabels', $event)"
					@itemUnselected="removeFilterValue('activityLabels', $event)"
				>
					{{ $tc('Tag', 2) }}
					<template #item="itemProps">
						<div class="flex items-start py-1 rounded hover:bg-sky-100 mx-4">
							<input type="checkbox" class="checkbox checkbox-xs rounded-full mr-3 mt-0.5" :checked="itemProps.selected" />
							<div class="overflow-hidden font-semibold">
								{{ itemProps.value.label }}
							</div>
						</div>
					</template>
				</MultiSelect>
			</div>

			<div class="col-span-12 @xl:col-span-4 @5xl:col-span-3">
				<MultiSelect class="w-full"
					:values="themeTagFilters"
					:selectedIds="filter.getParamValues('themeLabels').map(item => item.id)"
					:collapsable="true"
					dropdownMaxHeight="17rem"
					buttonFieldClasses="input-sm"
					inputFieldClasses="input-sm input-bordered bg-base"
					@itemSelected="addFilterValue('themeLabels', $event)"
					@itemUnselected="removeFilterValue('themeLabels', $event)"
				>
				{{ $tc('Theme', 2) }}
					<template #item="itemProps">
						<div class="flex items-start py-1 rounded hover:bg-yellow-100 mx-4">
							<input type="checkbox" class="checkbox checkbox-xs rounded-full ml-2 mr-3 mt-0.5" :checked="itemProps.selected" />
							<div class="overflow-hidden font-semibold">
								{{ itemProps.value.label }}
							</div>
						</div>
					</template>
				</MultiSelect>
			</div>

			<form class="flex items-center w-full col-span-12 @xl:col-span-4 @5xl:col-span-3" @submit="addKeyword">
				<input type="text" class="input input-sm w-full" :placeholder="$t('Search term')" />
				<button type="submit" class="py-1 -ml-9 pl-2 pr-1 border-l">
					<MagnifyingGlassIcon class="w-4 h-4" />
				</button>
			</form>

			<!-- <div class="col-span-6 @3xl:col-span-4 @5xl:col-span-3">
				<MultiSelect class="w-full"
					:values="dummyGoalFilterValues"
					:selectedIds="filter.getParamValues('goals').map(goal => goal.id)"
					:collapsable="true"
					dropdownMaxHeight="17rem"
					buttonFieldClasses="input-sm"
					inputFieldClasses="input-sm input-bordered bg-base"
					@itemSelected="addFilterValue('goals', $event)"
					@itemUnselected="removeFilterValue('goals', $event)"
				>
				{{ $tc('Goal', 2)  }}
					<template #item="itemProps">
						<div class="flex items-start py-1 rounded hover:bg-green-100 mx-4">
							<input type="checkbox" class="checkbox checkbox-xs rounded-full ml-2 mr-3 mt-0.5" :checked="itemProps.selected" />
							<div class="overflow-hidden font-semibold">
								{{ itemProps.value.label }}
							</div>
						</div>
					</template>
				</MultiSelect>
			</div> -->

		</div>
	</div>
</template>



<script lang="ts">
import { FilterDef, FilterParamValue } from "@/utils/FilterDefs.util";





import { useActivitiesStore } from "@/stores/Activities.store";
import { mapStores } from "pinia";

import MultiSelect from "@/components/ui/MultiSelect.vue";
import { CheckIcon, FunnelIcon, ChevronDownIcon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import Tag from "@/components/ui/Tag.vue";
import { useTagsStore } from "@/stores/Tags.store";
import { LessonPlanFilterDef } from "@/stores/LessonPlans.store";

export default {

	emits: ['change'],

	props: {
		initialFilter: {
			type: LessonPlanFilterDef,
			required: true,
		},
		resultCounter: {
			type: Number,
			default: null,
		},
		collapsable: {
			type: Boolean,
			default: true,
		},
		collapsed: {
			type: Boolean,
			default: true,
		},
	},

	components: {
		MultiSelect,
		Tag,
		CheckIcon, FunnelIcon, ChevronDownIcon, XMarkIcon, MagnifyingGlassIcon,
	},

	data() {
		return {
			filterPanelOpen: !this.collapsed,
			filter: null,
		}
	},

	computed: {
		...mapStores(useActivitiesStore, useTagsStore),

		activityTagFilters() {
			return this.tagsStore.getByType('activity', true)
				.map(tag => new FilterParamValue(tag.id, tag.name))
				.sort((a, b) => a.label.localeCompare(b.label));
		},

		themeTagFilters() {
			return this.tagsStore.getByType('theme', true)
				.map(tag => new FilterParamValue(tag.id, tag.name))
				.sort((a, b) => a.label.localeCompare(b.label));
		},
	},

	beforeMount() {
		this.filter = this.initialFilter;
	},

	mounted() {
		this.tagsStore.load();
	},

	methods: {

		resetFilter() {
			this.filter.clear();
			this.$emit('change', this.filter);
		},

		addKeyword(event) {
			event.preventDefault();
			const keyword = event.target[0].value;
			if(!keyword || keyword.length < 3) {
				return;
			}
			event.target[0].value = '';
			this.addFilterValue('keywords', new FilterParamValue(keyword, keyword));
		},

		addFilterValue(paramName, paramValue: FilterParamValue) {
			this.filter.addParamValue(paramName, paramValue);
			this.$emit('change', this.filter);
		},

		removeFilterValue(paramName, paramValue: FilterParamValue) {
			this.filter.removeParamValue(paramName, paramValue);
			this.$emit('change', this.filter);
		},

	}

}
</script>
