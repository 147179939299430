import { Icon } from "./Icon.model";
import { Symbol } from "./Symbol.model";
import { Classroom } from "@/models/Classroom.model";
import { useClassroomsStore } from "@/stores/Classrooms.store";
import { DateTime } from "luxon";
import { useIconsStore } from "@/stores/Icons.store";
import { usePupilsStore } from "@/stores/Pupils.store";
import { PupilClassroom } from "@/models/PupilClassroom.model";

export class Pupil {

	static COLORS = ["red", "yellow", "green", "blue", "pink", "gray"];

	static mapFromServer(data: any): Pupil {
		return (new Pupil()).setFromServerData(data);
	}

	public id: string | null = null;
	public firstName: string = '';
	public lastName: string = '';
	public active: boolean = true;
	public birthdate: DateTime | null = null;

	public classroomRelations: PupilClassroom[] = [];

	constructor() {}

	getFullName() {
		return this.firstName + ' ' + this.lastName;
	}

	// shortcuts for getting the pupil symbol/color for the currently selected classroom
	// (arguably not the greatest thing to auto-magically use the current classroom rather than explicitly passing it in)
	get symbol(): Symbol | Icon | null {
		return this.getIconForClassroom(useClassroomsStore().currentClassroom);
	}

	get color() {
		return this.getSymbolColorForClassroom(useClassroomsStore().currentClassroom);
	}

	/**
	 * WARNING.
	 * This method will EITHER return a Symbol or an Icon.
	 * This method is thus NOT the same as Pupil.symbol or Pupil.icon
	 */
	getIconForClassroom(classroom: Classroom): Symbol | Icon {
		const classroomRelation = this.getClassroomRelation(classroom);
		if (!classroomRelation) {
			return null;
		}
		if (classroomRelation.icon) {
			return classroomRelation.icon;
		}
		return classroomRelation.symbol;
	}

	/**
	 * WARNING.
	 * This method can be used to either set a Symbol or an Icon.
	 * @param symbol
	 */
	setIconForClassroom(classroom: Classroom, symbol: Symbol | Icon) {
		const classroomRelation = this.getClassroomRelation(classroom);
		if (!classroomRelation) {
			return null;
		}
		if (symbol instanceof Symbol) {
			classroomRelation.symbol = symbol;
			classroomRelation.icon = null;
		} else if (symbol instanceof Icon) {
			classroomRelation.icon = symbol;
			classroomRelation.symbol = null;
		}
		return this;
	}

	getSymbolColorForClassroom(classroom: Classroom) {
		const classroomRelation = this.getClassroomRelation(classroom);
		if (!classroomRelation) {
			return null;
		}
		return classroomRelation.color;
	}

	setSymbolColorForClassroom(classroom: Classroom, color: string) {
		const classroomRelation = this.getClassroomRelation(classroom);
		if (classroomRelation) {
			classroomRelation.color = color;
		}
	}

	belongsToClassroom(classroom: Classroom) {
		return this.classroomRelations.some((classroomRelation) => {
			return classroomRelation.active && classroomRelation.classroom.id === classroom.id;
		});
	}

	setFromServerData(data: any) {
		this.id = data.id;
		this.firstName = data.firstName;
		this.lastName = data.lastName;
		this.birthdate = data.birthdate ? DateTime.fromFormat(data.birthdate, 'yyyy-MM-dd') : null;

		if (typeof(data.active) !== 'undefined') {
			this.active = data.active;
		}

		if (data.classrooms) {
			this.classroomRelations = data.classrooms.map((classroomData: any) => {
				return new PupilClassroom(
					Classroom.mapFromServer(classroomData),
					classroomData.symbol ? Symbol.mapFromServer(classroomData.symbol) : null,
					classroomData.icon ? Icon.mapFromServer(classroomData.icon) : null,
					classroomData.color
				);
			});
		}

		return this;
	}

	getClassroomRelation(classroom: Classroom = null): PupilClassroom | undefined {
		if (!classroom) {	// default to the currently selected classroom
			classroom = useClassroomsStore().currentClassroom;
			if (!classroom) {
				return null;
			}
		}
		return this.classroomRelations.find((classroomRelation) => {
			return classroomRelation.classroom.id === classroom.id;
		});
	}

	async toggleClassroom(classroom: Classroom, stateActive: boolean) {
		const classroomRelation = this.getClassroomRelation(classroom);
		if (!classroomRelation && stateActive) {

			let color = 'gray';
			// let color = null;
			// Pick random symbol
			// let symbol = await this.pickRandomSymbol();
			let symbol = null;

			this.classroomRelations.push(
				new PupilClassroom(
					classroom,
					symbol,
					null,
					color
				)
			);

			return;
		}

		if (!classroomRelation) {
			return;
		}

		classroomRelation.active = stateActive;
	}

	async pickRandomSymbol() {
		await useIconsStore().loadStandardPupilIcons();

		let symbol;
		for (let tries = 0; tries < 10; tries++) {
			symbol = useIconsStore().pupilSymbols[Math.floor(Math.random() * useIconsStore().pupilSymbols.length)];

			if (!usePupilsStore().isIconUsed(symbol)) {
				break;
			}
		}

		return symbol;
	}

	getServerData() {
		return {
			id: this.id,
			firstName: this.firstName,
			lastName: this.lastName,
			birthdate: this.birthdate?.toFormat('yyyy-MM-dd'),
			classrooms: this.classroomRelations
				.filter((classroomData) => classroomData.active)
				.map((classroomData) => {
					return {
						id: classroomData.classroom.id,
						color: classroomData.color,
						icon: classroomData.icon ? classroomData.icon.getServerData() : null,
						symbol: classroomData.symbol ? classroomData.symbol.getServerData() : null
					}
				})
		};
	}

	clone(): Pupil {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
		clonedInstance.classroomRelations = this.classroomRelations.map((classroomRelation) => {
			return classroomRelation.clone();
		});
		return clonedInstance;
	}

}
