export class Goal {


	public id: string | null = null;
	public organisationId: string | null = null;
	public source: string;
	public sourceId?: string = null;
	public sourceParentPath: string;
	public sourcePathId?: string = null;
	public title?: string;
	public customTitle?: string = null;
	public info: string = null;
	public tags: string[] = [];
	public color: string = null;	// a goal can adopt a color from higher level group if available
	// public importedBy: null;

	// frontend-only properties
	public pendingSync: boolean = false;

	get fullPath(): string {
		let path = '';
		if (this.sourceParentPath) {
			path = this.sourceParentPath + '.';
		}

		if (this.sourcePathId) {
			path += this.sourcePathId;
		} else {
			path += + '.x';
		}

		return path;
	}

	isSubgoal(): boolean {
		return this.sourcePathId === null;
	}

	/**
	 * Create a Goal object based on data coming from one of our own JSON curriculum files
	 */
	static mapFromStaticGoalSourceData(data: any, sourceId: string): Goal {
		let g = new Goal();
		g.source = sourceId;
		g.title = data.title;
		const splitPath = data.path.split('.');
		g.sourceParentPath = splitPath.slice(0, -1).join('.');
		g.sourcePathId = splitPath.pop();
		g.info = data.info;
		if(data.meta) {
			if(data.meta.tags) {
				g.tags = data.meta.tags;
			}
		}
		return g;
	}

	static mapFromServer(data: any): Goal {
		return (new Goal()).setFromServerData(data);
	}


	setFromServerData(data: any) {
		this.id = data.id;
		this.source = data.source;
		this.sourceId = data.sourceId;
		this.sourceParentPath = data.parentPath;
		this.sourcePathId = data.pathId;
		this.title = data.title;
		this.customTitle = data.customTitle;
		this.color = data.color;

		if (data.tags) {
			this.tags = data.tags.map((tag: any) => {
				return tag.name;
			});
		}

		return this;
	}

	setFromModel(goal: Goal) {

		Object.assign(this, goal);

	}

	getServerData() {
		return {
			id: this.id,
			source: this.source,
			sourceId: this.sourceId,
			parentPath: this.sourceParentPath,
			pathId: this.sourcePathId,
			title: this.title,
			customTitle: this.customTitle,
			color: this.color,
			info: this.info,
			tags: this.tags.map((tag) => {
				return {
					name: tag,
					type: 'goal'
				}
			})
		};
	}

	getLinkData() {
		if (this.id) {
			return {
				id: this.id,
			}
		} else {
			return this.getServerData();
		}
	}

	public equals(other: Goal): boolean {
		return (this.id && this.id === other.id) || (this.sourceId && this.sourceId === other.sourceId);
	}

	clone(): Goal {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
		return clonedInstance;
	}

}
