<template>

	<Modal>
		<EditableTable :columns="columns" v-model="spreadsheetData" />

		<div class="modal-footer flex">
			<button type="button" class="btn btn-primary" @click="importPupils()">{{ $t('Import') }}</button>
		</div>
	</Modal>

</template>

<script lang="ts">
import {TrashIcon} from "@heroicons/vue/24/outline";
import EditableTable from "@/components/EditableTable.vue";
import Modal from "@/components/ui/Modal.vue";
import {DateTime} from "luxon";
import {mapStores} from "pinia";
import {usePupilsStore} from "@/stores/Pupils.store";
import {Pupil} from "@/models/Pupil.model";

export default {
	components: {
		Modal,
		EditableTable,
		TrashIcon
	},

	data() {
		return {
			spreadsheetData: [],
			lastChangeWasPastedTableData: false,
		};
	},

	computed: {

		...mapStores(usePupilsStore),

		columns() {
			return [
				{
					title: 'First name',
					key: 'firstName',
					validator: (value) => {
						return value.length > 0;
					}
				},
				{
					title: 'Name',
					key: 'name',
					validator: (value) => {
						return value.length > 0;
					}
				},
				{
					title: 'Birthdate',
					key: 'birthdate',
					pasteTransformer: (value: string) => {
						const date = DateTime.fromFormat(value, 'd/M/yy');
						return date.isValid ? date.toFormat('dd/MM/yyyy') : '';

					},
					validator: (value: string) => {
						return value.length > 3;
					}
				},
			]
		},

	},

	methods: {
		async importPupils() {
			// Convert input to Pupil objects
			const pupils = this.spreadsheetData.map((row) => {
				const pupil = new Pupil();
				pupil.firstName = row[0].value;
				pupil.lastName = row[1].value;
				pupil.birthdate = row[2].value ? DateTime.fromFormat(row[2].value, 'dd/MM/yyyy') : null;

				return pupil;
			});

			// Send pupils to server
			try {
				await this.pupilsStore.bulkSave(pupils);
			} finally {
				this.$emit('requestClose');
			}
		}
	}

}
</script>
