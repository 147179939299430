<template>
	<div class="float-right sticky z-30 top-2 pr-4 -mb-6 print:hidden">
		<button type="button" class="btn btn-primary btn-circle btn-lg" @click="$emit('push')">

			<component v-bind:is="icon" class="w-8 h-8"></component>

		</button>
	</div>
</template>

<script lang="ts">
import { PlusIcon } from "@heroicons/vue/24/outline";

export default {
	emit: ['push'],
	components: {
		PlusIcon
	},
	props: {
		icon: {
			default: PlusIcon
		},
	}
}
</script>
