<template>

	<TheStdLayout>

		<template #pageheader>
			<div class="flex gap-8 items-center">
				<span>{{ $t('Agenda') }}</span>
				<AgendaSearchBox @agenda-go-to="handleCalendarNav"></AgendaSearchBox>
			</div>
		</template>

		<LessonFormModal
			v-model:showActivities="showChoiceBoardSettings"
			v-if="showLessonDialog"
			:lesson="selectedLesson"
			@change="handleSelectedLessonChange"
			@requestClose="handleSelectedLessonClose"
			@cancel="handleSelectedLessonClose"
		>
		</LessonFormModal>

		<AgendaThemePeriodEditor
			v-if="showThemeEditor"
			:themePeriod="selectedThemePeriod"
			@requestClose="showThemeEditor = false"
		/>

		<div class="sm:ml-4" style="max-width: 1100px;">

			<div class="sticky top-0 bg-white/50 backdrop-blur-sm z-10">
				<AgendaToolbar v-model:calendarIsoDate="calendarIsoDate" @calendar-go-to="handleCalendarNav" @add-theme-period="createNewTheme" />

				<div class="flex ml-[44px] gap-0.5">
					<div class="basis-1/5" v-for="day in allDaysOfDisplayedWeek" :key="day">
						<AgendaDayHeader :sourceDate="day"/>
					</div>
				</div>
			</div>

			<div class="relative">

				<div v-if="preparingLoad || loading" class="absolute top-10 z-10 w-full flex justify-center"
					 style="animation: button-pop 0.25s ease-out;">
					<div
						class="w-full max-w-xl bg-base-100 p-4 text-center flex items-center justify-center gap-2 rounded-xl">
						<span class="loading loading-spinner loading-md"></span>
						{{ $t('Loading, please wait') }}...
					</div>
				</div>

				<FullCalendar ref="fullCal" :options="calendarOptions">

					<template v-slot:eventContent='arg'>

						<template v-if="arg.event.extendedProps.type === 'lesson'">

							<div class="fc-event-main-frame p-1 border-0 print:border-1 rounded-sm relative group"
								 :class="[
										 'text-primary-content',
										 arg.event.extendedProps.lessonItemLength === 0 ? 'empty' : '',
										 arg.event.extendedProps.short ? 'short' : ''
									 ]"
								:style="{ backgroundColor: arg.event.extendedProps.color, borderColor: arg.event.extendedProps.color }"
							>
								<div class="fc-event-title-container leading-3 print:border-2" :style="{borderColor: arg.event.extendedProps.color}">
									<div class="fc-event-title fc-sticky font-semibold">
										<div class="leading-4">
											{{ arg.event.title }}
										</div>
										<div v-if="!arg.event.extendedProps.short" class="fc-event-time">
											{{ localTime(arg.event.start) }} - {{ localTime(arg.event.end) }}
										</div>
									</div>
									<div class="event-summary">
										{{ arg.event.extendedProps.lessonSummary }}
									</div>
								</div>
								<div
									class="clone-btn-cont absolute left-0 overflow-hidden h-full w-0 group-hover:w-8 flex flex-col justify-center"
									style="transition: width .1s ease-in-out;"
								>
									<button type="button"
											@mousedown="activateCloneMode"
											@mouseup="deactivateCloneMode"
											@click.stop="deactivateCloneMode"
											class="clone-button cursor-grab w-full py-3 px-2 border-white bg-white bg-opacity-80 rounded-r-full print:hidden"
									>
										<DocumentDuplicateIcon class="w-4 h-4"/>
									</button>
								</div>

								<div class="absolute inset-0 hidden group-[.saving]:block text-center">
									<span class="loading loading-spinner loading-xs"></span>
								</div>
							</div>

						</template>


						<template v-if="arg.event.extendedProps.type === 'theme'">
							<div class="fc-event-main-frame pl-1 cursor-pointer bg-yellow-500 bg-opacity-40 rounded-md">
								<div class="fc-event-title-container py-1 px-2">
									<div class="fc-event-title leading-4 font-bold uppercase text-xs text-yellow-800">
										{{ arg.event.title }}
									</div>
								</div>
							</div>
						</template>
					</template>

				</FullCalendar>
			</div>

		</div>

	</TheStdLayout>

</template>

<style>
.fc-day-today {
	background: hsl(var(--p) / 0.1) !important;
}

.fc-v-event {
	border: none !important;
	background: #eee !important;
}
.fc-h-event {
	border: none !important;
	background: none;
}

.fc-event-dragging {
	cursor: grabbing;
}

.fc .fc-timegrid-slot-label {
	font-size: 0.75rem;
	vertical-align: top !important;
}
.fc-event-time {
	@apply inline-block bg-white bg-opacity-60 rounded px-1 py-0.5;
}
.fc-event-main-frame.short .fc-event-title {
	@apply flex flex-row gap-1;
}

.fc-event-main-frame.empty {
	background-image: repeating-linear-gradient(33deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07) 6px, rgba(0, 0, 0, 0.0) 6px, rgba(0, 0, 0, 0.0) 12px);
}

.agenda-dragging .fc {
	@apply cursor-grabbing;
}

.agenda-dragging .fc a {
	@apply cursor-grabbing;
}

.agenda-dragging .clone-button {
	@apply cursor-grabbing;
}

.agenda-dragging .clone-btn-cont {
	display: none;
}
.fc-event-main-frame {
	overflow: hidden;
}
.fc-event-main-frame.short .clone-button {
	display: none;
}

.fc-event-main-frame.short:hover .clone-button {
	display: block;
}
.event-summary {
	@apply text-xs;
}

</style>

<script lang="ts">
import { DateTime, Interval } from "luxon";
import { DateSelectArg, EventClickArg } from '@fullcalendar/core';
import FullCalendar from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { CALENDAR_SETTINGS, TIMEGRID_SETTINGS } from "../settings/FullCalendar";
import AgendaDayHeader from "./AgendaDayHeader.vue";
import AgendaSearchBox from "./AgendaSearchBox.vue";
import Modal from './ui/Modal.vue';
import Dropdown from './ui/Dropdown.vue';
import LessonFormModal from './LessonFormModal.vue';
import AgendaToolbar from './AgendaToolbar.vue';
import TimePicker from './ui/TimePicker.vue';
import {
	ChevronLeftIcon, ChevronRightIcon, DocumentDuplicateIcon, PrinterIcon, ViewfinderCircleIcon,
	MagnifyingGlassIcon, CalendarDaysIcon, XMarkIcon, PlusIcon,
	QuestionMarkCircleIcon, EllipsisVerticalIcon, CloudArrowUpIcon
} from '@heroicons/vue/24/outline';
import { mapActions, mapState, mapStores } from "pinia";
import { useIconsStore } from "@/stores/Icons.store";
import { useIconLibrariesStore } from "@/stores/IconLibraries.store";
import config from "@/config/app.config";
import { UnsavedChanges } from "@/utils/UnsavedChanges.util";
import { useLessonsStore } from "@/stores/Lessons.store";
import { Lesson } from "@/models/Lesson.model";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/ui/ConfirmModal.vue";
import AgendaThemePeriodEditor from "@/components/AgendaThemePeriodEditor.vue";
import {useThemesStore} from "@/stores/Themes.store";
import {AbstractAgendaItemModel} from "@/models/AbstractAgendaItem.model";
import {useOrganisationsStore} from "@/stores/Organisations.store";

export default {
	components: {
		FullCalendar,
		AgendaDayHeader,
		AgendaSearchBox,
		AgendaToolbar,
		Modal,
		Dropdown,
		TimePicker,
		LessonFormModal,
		AgendaThemePeriodEditor,
		ChevronLeftIcon,
		ChevronRightIcon,
		DocumentDuplicateIcon,
		PrinterIcon,
		ViewfinderCircleIcon,
		MagnifyingGlassIcon,
		CalendarDaysIcon,
		XMarkIcon,
		PlusIcon,
		QuestionMarkCircleIcon,
		EllipsisVerticalIcon,
		CloudArrowUpIcon
	},
	data() {
		return {
			calendarDate: DateTime.now(),
			showLessonDialog: false,
			showThemeEditor: false,
			showChoiceBoardSettings: false,
			selectedLesson: null,
			selectedCalendarEvent: null,
			cloneModeRequested: false,
			cloneMode: false,
			isDragging: false,
			loadTimeout: null,
			preparingLoad: false,
			scheduledLessonSaveTimeout: null,
			selectedThemePeriod: null,

			allDaysOfDisplayedWeek: [],
			includeWeekends: false,
			visibleTimeRange: {
				start: '08:00:00',
				end: '17:00:00',
			},
			businessHours: {
				start: '08:00',
				end: '17:00',
			}
		};
	},

	beforeMount() {
		this.recalculateVisibleDateTimeRanges();
	},

	mounted() {
		this.calendarApi = this.$refs.fullCal.getApi();
		this.iconsStore.loadStandardActivityIcons();
	},

	beforeRouteLeave() {
		return UnsavedChanges.nonexistentOrIgnorable();
	},

	computed: {

		...mapStores(useLessonsStore, useIconsStore, useIconLibrariesStore, useThemesStore, useOrganisationsStore),

		calendarIsoDate() {
			return this.calendarDate.toISODate();
		},

		calendarOptions() {

			// Always make sure that ALL events are visible
			// (even if they are outside of the current view)

			const settings = {
				plugins: [timeGridPlugin, interactionPlugin],
				initialDate: this.calendarDate.toJSDate(),
				locale: this.$i18n.locale,
				businessHours: [
					{
						daysOfWeek: [ 0, 1, 2, 3, 4, 5, 6, ],
						startTime: this.businessHours.start,
						endTime: this.businessHours.end,
					}
				],
				...CALENDAR_SETTINGS,
				firstDay: this.calendarDate.startOf("week").weekday,
				views: {
					timeGrid: {
						...TIMEGRID_SETTINGS,
						weekends: this.includeWeekends,
						slotMinTime: this.visibleTimeRange.start,
						slotMaxTime: this.visibleTimeRange.end,
					},
				},

				events: this.events,
				eventDataTransform: this.lessonToCalendarEvent,
				select: this.handleDateSelect,
				eventClick: this.handleEventClick,
				datesSet: this.handleNewWeekViewLoaded,
				eventDrop: this.handleEventChange,
				eventResize: this.handleEventChange,
				eventDragStart: this.handleEventDragStart,
				eventDragStop: this.handleEventDragStop,
			};

			return settings;
		},

		localTime() {
			return (date: Date) => {
				return DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_SIMPLE);
			};
		},

		events() {
			return [
				...this.lessonsStore.events,
				...this.themesStore.events
			]
		},

		loading() {
			return this.lessonsStore.loading || this.themesStore.loading;
		},

	},

	methods: {

		handleCalendarNav(action: String, date: DateTime) {

			let calendarApi = this.$refs.fullCal.getApi();
			switch (action) {
				case "prev":
					calendarApi.prev();
					break;
				case "next":
					calendarApi.next();
					break;
				case "today":
					calendarApi.today();
					break;
				case "date":
					calendarApi.gotoDate(date.toJSDate());
					break;
				default:
					break;
			}

		},

		handleNewWeekViewLoaded(options) {

			let calendarApi = this.$refs.fullCal.getApi();

			// we must add one day as the calendar uses sunday as first day of the week, and we don't.
			this.calendarDate = DateTime.fromJSDate(calendarApi.getDate()).plus({days: 1});

			// Recalculate all days of the week (before events are loaded, we need to do this again after)
			this.recalculateVisibleDateTimeRanges();

			if (this.loadTimeout) {
				this.preparingLoad = false;
				clearTimeout(this.loadTimeout);
			}

			if (this.lessonsStore.isRangedLoaded(options.start, options.end)) {
				// Set the timeout, but do not show a loading spinner

				// We must set a delay here as handleNewWeekViewLoaded() is sometimes called frequently,
				// for example when the setting 'include weekends' is computed.

				this.loadTimeout = setTimeout(() => {
					this.loadDateRange(options.start, options.end);
					this.loadTimeout = null;
				}, 500);
			} else {
				this.preparingLoad = true;
				this.loadTimeout = setTimeout(() => {
					this.loadDateRange(options.start, options.end);
					this.preparingLoad = false;
					this.loadTimeout = null;
				}, 500);

			}

		},

		/**
		 * @param start
		 * @param end
		 */
		async loadDateRange(start: Date, end: Date) {

			try {
				await Promise.all([
					this.lessonsStore.loadBetween(start, end),
					this.themesStore.loadBetween(start, end)
				]);

				this.recalculateVisibleDateTimeRanges();
			} catch (e) {
				this.$notify({
					title: "Error!",
					text: e.toString(),
					type: "error",
				});

				throw e;
			}

		},

		handleEventClick(clickInfo: EventClickArg) {

			switch (clickInfo.event.extendedProps.type) {
				case 'lesson':
					return this.handleLessonClick(clickInfo);

				case 'theme':
					return this.handleThemeClick(clickInfo);
			}

		},

		handleLessonClick(clickInfo: EventClickArg) {

			if (clickInfo.event.extendedProps.activities) {
				this.showChoiceBoardSettings = true;
			}

			this.selectedLesson = this.lessonsStore.getFromId(clickInfo.event.id);
			if (this.selectedLesson) {
				this.selectEvent(this.selectedLesson, clickInfo.event);
			}

		},

		handleThemeClick(clickInfo: EventClickArg) {

			const id = clickInfo.event.id.substring('theme-'.length);

			this.selectedThemePeriod = this.themesStore.getFromId(id);
			if (this.selectedThemePeriod) {
				this.showThemeEditor = true;
			}

		},

		createNewTheme() {

			const themeIntervalStart = DateTime.fromJSDate(this.calendarApi.view.activeStart).startOf('week');
			const themeIntervalEnd = DateTime.fromJSDate(this.calendarApi.view.activeStart).endOf('week');

			//console.log(themeIntervalStart.toISO(), themeIntervalEnd.toISO());

			this.selectedThemePeriod = this.themesStore.new(
				Interval.fromDateTimes(themeIntervalStart, themeIntervalEnd)
			);
			this.showThemeEditor = true;

		},

		handleDateSelect(selectInfo: DateSelectArg) {

			// don't allow selection that span multiple days
			if (selectInfo.end.getDate() !== selectInfo.start.getDate()) {
				this.calendarApi.unselect();
				return;
			}

			this.calendarApi.unselect();

			const lesson = this.lessonsStore.new();
			lesson.start = selectInfo.start;
			lesson.end = selectInfo.end;

			// If a time block of < 5 minutes is selected (usually through a 'long press' on a touch device), we'll make it 15 minutes long
			const timeDifference = Math.abs(lesson.end.getTime() - lesson.start.getTime());
			const minutesDifference = Math.floor(timeDifference / (1000 * 60));
			if(minutesDifference <= 5) {
				lesson.end.setMinutes(lesson.start.getMinutes() + 15);
			}

			this.selectedCalendarEvent = this.calendarApi.addEvent(this.lessonToCalendarEvent(lesson));
			this.selectEvent(lesson, this.selectedCalendarEvent);

		},

		handleSelectedLessonChange(lesson: Lesson) {

			if (!this.selectedCalendarEvent) {
				console.warn('Event received even though no selectedCalenderEvent is set.');
				return;
			}
			this.setCalendarEventProperties(lesson, this.selectedCalendarEvent);

		},

		selectEvent(storeEvent, calendarEvent) {
			this.selectedLesson = storeEvent;
			this.selectedCalendarEvent = calendarEvent;

			this.showLessonDialog = true;
		},

		handleSelectedLessonClose() {
			if (!this.selectedCalendarEvent) {
				console.warn('Event received even though no selectedCalenderEvent is set.');
				return;
			}

			if (!this.selectedLesson.hasId()) {
				this.selectedCalendarEvent.remove();
			}
			this.showLessonDialog = false

			// Lesson dates might have changed, so recalculate the visible time range
			this.recalculateVisibleDateTimeRanges()
		},

		/**
		 * Called when an event is dropped or resized.
		 * ONLY gets called if the event was actually moved and the attributes have been changed.
		 * @param dropInfo
		 */
		async handleEventChange(dropInfo) {

			// Clear the 'backup' store event interval, if it exists
			if (this.scheduledLessonSaveTimeout) {
				clearTimeout(this.scheduledLessonSaveTimeout);
				this.scheduledLessonSaveTimeout = null;
			}

			await this.storeEvent(dropInfo);

		},

		/**
		 * Called when a drag operation is stopped.
		 * Note that the attributes (start- and enddate) of the event have not changed yet at this point.
		 * @param evt
		 */
		handleEventDragStop(evt) {
			document.body.classList.remove('agenda-dragging');

			// If we are in clone mode, we need to do something tricky.
			// As we are dragging the *original* event, handleEventChange will not be called if we dragged the cloned
			// event at exactly the same position of the original event (as the attributes have not changed).
			// Therefore, we need to check if the event was dragged at the same position, and if so,
			// we need to call handleEventChange manually... but only if the event was dragged at all.
			if (this.cloneMode) {
				// Wait 1ms (to make sure the event has been dropped and the attributes have been changed)
				// and then check if the event was dragged at all.
				// If not, we need to call handleEventChange manually.

				// Update: the above didn't work as the event objects are immutable, so we couldn't check for
				// changes. Instead, we register a backup save timeout and clear that when the actual save event happens.
				this.scheduledLessonSaveTimeout = setTimeout(() => {
					this.storeEvent(evt);
				}, 1);
			}
		},

		async storeEvent(evt, force: boolean = false) {

			evt.el.classList.add('saving');
			evt.el.classList.add('group');

			try {
				// Clone mode? Then create a new lesson instead
				if (this.cloneMode) {
					const lesson = this.lessonsStore.clone(evt.event.id);
					lesson.start = evt.event.start;
					lesson.end = evt.event.end;

					await this.lessonsStore.store(lesson, force);
				} else {
					await this.lessonsStore.updateLesson(evt.event.id, {
						// type: evt.event.extendedProps.type,
						// title: evt.event.title,
						start: evt.event.start,
						end: evt.event.end,
					}, force);
				}

				// Also recalculate the visible time range, as the event might have been moved to a different day
				this.recalculateVisibleDateTimeRanges();

			} catch (e) {

				let canForce = false;
				if (e.response?.status === 409) {

					const { reveal } = createConfirmDialog(ConfirmModal);
					canForce = true;

					const confirmForce = await reveal({
						title: this.$t('Activity board will reset'),
						message: this.$t('#notification: activity board will reset')
					});

					if (!confirmForce.isCanceled) {
						await this.storeEvent(evt, true);
						return;
					}
				}

				try {
					evt.revert();

					const lesson = this.lessonsStore.getFromId(evt.event.id);
					lesson.start = evt.oldEvent.start;
					lesson.end = evt.oldEvent.end;
				} catch (e) {}

				if (!canForce) {
					this.$notify({
						title: "Error!",
						text: e.toString(),
						type: "error",
					});
				}
			}

			this.cloneMode = false;
			evt.el.classList.remove('saving');

		},

		lessonToCalendarEvent(event: AbstractAgendaItemModel) {

			return event.asFullCalendarEvent();

		},

		/**
		 * @param lesson
		 * @param calendarEvent
		 */
		setCalendarEventProperties(lesson: Lesson, calendarEvent: any) {
			calendarEvent.setDates(lesson.start, lesson.end);
			calendarEvent.setProp('title', lesson.title ?? '');

			if (lesson.color) {
				calendarEvent.setExtendedProp('color', lesson.hexColor);
			}
		},

		activateCloneMode() {
			this.cloneModeRequested = true;
		},

		deactivateCloneMode() {
			this.cloneModeRequested = false;
		},

		handleEventDragStart(evt) {
			this.isDragging = true;

			// Add agenda-dragging class to body as long as we are dragging, as the previously used class was
			// removed from fullcalendar on March 21, 2023
			// (https://github.com/fullcalendar/fullcalendar/commit/8dcc58d40f0da9ac087f0139f61a2afbf31bb38a)
			document.body.classList.add('agenda-dragging');

			if (!this.cloneModeRequested) {
				return;
			}

			this.cloneMode = true;
			this.cloneModeRequested = false;

			// Add the original event as 'new' event
			const lesson = this.lessonsStore.getFromId(evt.event.id);
			this.calendarApi.addEvent(this.lessonToCalendarEvent(lesson));
		},

		/**
		 * To avoid unnecessary recalculation of the visible time range, we'll only do it when the week changes.
		 */
		recalculateVisibleDateTimeRanges() {

			// First, figure out how far down we have scrolled
			let scrollPosition = null;
			if (this.$refs.fullCal) {
				scrollPosition = this.$refs.fullCal.$el.querySelector('.fc-scroller-liquid-absolute')?.scrollTop;
			}

			let includeWeekends = false;

			let slotMinHours = 8;
			let slotMaxHours = 17;

			let businessHoursStart = '08:00:00';
			let businessHoursEnd = '17:00:00';

			if (this.organisationsStore?.currentOrganisation.agenda_settings?.school_hours_start) {
				const schoolStart = DateTime.fromFormat(this.organisationsStore.currentOrganisation.agenda_settings.school_hours_start, 'H:m');

				slotMinHours = schoolStart.hour;
				businessHoursStart = schoolStart.toFormat('HH:mm:ss');
			}

			if (this.organisationsStore?.currentOrganisation.agenda_settings?.school_hours_end) {
				const schoolEnd = DateTime.fromFormat(this.organisationsStore.currentOrganisation.agenda_settings.school_hours_end, 'H:m');

				slotMaxHours = schoolEnd.hour;
				businessHoursEnd = schoolEnd.toFormat('HH:mm:ss');
			}

			if (this.organisationsStore?.currentOrganisation.agenda_settings?.include_weekends) {
				includeWeekends = true;
			}

			this.lessonsStore.getBetween(
				this.calendarDate.startOf("week").toJSDate(),
				this.calendarDate.endOf("week").toJSDate()
			).forEach((lesson: Lesson) => {

				// Start and end hours
				if (slotMinHours > DateTime.fromJSDate(lesson.start).hour) {
					slotMinHours = DateTime.fromJSDate(lesson.start).hour;

				}
				if (slotMaxHours < DateTime.fromJSDate(lesson.end).hour + 1) {
					slotMaxHours = DateTime.fromJSDate(lesson.end).hour + 1;
				}

				// Is event in the weekend? Then include weekends.
				if (!includeWeekends && lesson.start.getDay() === 0 || lesson.start.getDay() === 6) {
					includeWeekends = true;
				}

			});

			if (slotMaxHours < slotMinHours) {
				slotMaxHours = 24;
			}

			// Visible hours = a little bit extra
			this.visibleTimeRange = {
				start: slotMinHours + ':00:00',
				end: slotMaxHours + ':00:00'
			};

			// Business hours = exact
			this.businessHours = {
				start: businessHoursStart,
				end: businessHoursEnd,
			};

			this.includeWeekends = includeWeekends;

			//
			// Set all days of displayed week
			//
			let monday = this.calendarDate.startOf("week");
			const days = [
				monday,
				monday.plus({days: 1}),
				monday.plus({days: 2}),
				monday.plus({days: 3}),
				monday.plus({days: 4})
			];

			if (includeWeekends) {
				days.push(
					monday.plus({days: 5}),
					monday.plus({days: 6})
				);
			}
			this.allDaysOfDisplayedWeek = days;

			if (scrollPosition) {
				this.$nextTick(() => {
					this.$refs.fullCal.$el.querySelector('.fc-scroller-liquid-absolute').scrollTop = scrollPosition;
				});
			}
		}
	},
};
</script>
