<template>

	<div class="mx-auto">

		<div class="overflow-x-auto">
			<table
				id="spreadsheet"
				ref="spreadsheet"
				@keydown.up="navigateSheet($event, 'up')"
				@keydown.down="navigateSheet($event, 'down')"
				@keydown.left="navigateSheet($event, 'left')"
				@keydown.right="navigateSheet($event, 'right')"
				class="w-full"
			>
				<thead>

					<tr>
						<td v-for="column in columns" :key="column.key">
							{{ column.title }}
						</td>
					</tr>

				</thead>
				<tbody>

				<tr v-for="(row, rowIndex) in spreadsheetData" :data-row="rowIndex">

					<td v-for="(colum, columnIndex) in columns" class="relative" >

						<span class="row-number" v-if="columnIndex === 0">{{ rowIndex + 1 }}</span>

						<div class="textarea-grow-wrap">
							<textarea
								:data-row="rowIndex"
								:data-col="columnIndex"
								rows="1"
								v-model="spreadsheetData[rowIndex][columnIndex].value"
								:class="[validateColumn(columnIndex, spreadsheetData[rowIndex][columnIndex].value) ? 'bg-white' : 'bg-red-100' ]"
								@paste="handlePaste($event, rowIndex)"
								@change="(event) => { changeValue(rowIndex, columnIndex, event) }"
							></textarea>
						</div>
					</td>

				</tr>
				</tbody>
			</table>


		</div>


	</div>

</template>

<style scoped>
#import-instructions code {
	@apply bg-white px-1 py-1 text-xs;
}
table#spreadsheet {
	@apply table w-5/6 max-w-4xl ml-10;
}
table#spreadsheet tbody tr {
	@apply border-b border-base-200;
}
table#spreadsheet tbody tr .row-number {
	@apply absolute flex justify-center items-center -left-10 top-0 bg-base-100 h-full w-10;
}
table#spreadsheet tbody tr .clear-button {
	@apply absolute flex justify-center items-center top-0 -right-10 w-10 h-full;
}
table#spreadsheet tbody td {
	@apply border-base-200 p-0 h-10;
}
table#spreadsheet tbody td:first-child {
	@apply relative border-l border-r;
}
table#spreadsheet tbody td:not(:first-child) {
	@apply border-r;
}
table#spreadsheet tbody td input {
	@apply w-full h-full block border border-white px-2 outline-none focus:border-blue-300;
}
table#spreadsheet tbody td textarea, .textarea-grow-wrap::after {
	@apply w-full min-h-full block whitespace-pre-wrap border border-white px-2 outline-none focus:border-blue-300 resize-none py-2;
}

/* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
.textarea-grow-wrap {
	@apply grid;
}
.textarea-grow-wrap::after, .textarea-grow-wrap > textarea {
	grid-area: 1 / 1 / 2 / 2;
	@apply h-full;
}
.textarea-grow-wrap::after {
	content: attr(data-replicated-value) " ";
	visibility: hidden;
}
</style>


<script lang="ts">
import { nextTick } from 'vue';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { min } from 'date-fns';

export default {
	components: {
		TrashIcon,
	},

	props: {

		modelValue: {
			type: Array,
			required: true
		},

		columns: {
			type: Array,
			required: true
		},


	},

	data() {
		return {
			lastChangeWasPastedTableData: false,
			spreadsheetData: [],
		};
	},

	computed: {

		validateColumn() {
			return (columnIndex, value) => {
				if (!value) {
					return true;
				}

				if (this.columns[columnIndex]?.validator) {
					return this.columns[columnIndex].validator(value);
				}
				return true;
			}
		}

	},

	watch: {
		spreadsheetData: {
			// add a new row as soon as the last row has some content
			handler: function (val, oldVal) {
				const lastRow = this.spreadsheetData[this.spreadsheetData.length - 1];

				if(lastRow && !this.isEmptyRow(lastRow)) {
					this.spreadsheetData.push(this.newEmptyRow());
				}
			},
			deep: true
		}
	},

	methods: {

		navigateSheet(event, direction) {

			const row = Number(event.target.dataset.row);
			const col = Number(event.target.dataset.col);

			let caretPos = 0;

			switch(direction) {
				case 'up':
					if(row > 0) {
						this.$refs.spreadsheet.querySelector(`[data-row="${row - 1}"][data-col="${col}"]`).focus();
					}
					break;

				case 'down':
					if(row < this.spreadsheetData.length - 1) {
						this.$refs.spreadsheet.querySelector(`[data-row="${row + 1}"][data-col="${col}"]`).focus();
					}
					break;

				case 'left':
					caretPos = event.target.selectionStart;
					if(caretPos > 0) {
						return;
					}

					if(col > 0) {
						const prevCell = this.$refs.spreadsheet.querySelector(`[data-row="${row}"][data-col="${col - 1}"]`);
						prevCell.focus();
						setTimeout(() => {
							let endOfContent = prevCell.value.length;
							prevCell.setSelectionRange(endOfContent, endOfContent);
						}, 50);
					} else if(row > 0) {
						const lastCellPrevRow = this.$refs.spreadsheet.querySelector(`[data-row="${row - 1}"][data-col="${this.columns.length - 1}"]`);
						lastCellPrevRow.focus();
					}
					break;

				case 'right':
					caretPos = event.target.selectionStart;
					if(caretPos < event.target.value.length) {
						return;
					}

					if(col < this.columns.length - 1) {
						const nextCell = this.$refs.spreadsheet.querySelector(`[data-row="${row}"][data-col="${col + 1}"]`);
						nextCell.focus();
						setTimeout(() => {
							nextCell.setSelectionRange(0, 0);
						}, 50);
					} else if(row < this.spreadsheetData.length - 1) {
						const firstCellNextRow = this.$refs.spreadsheet.querySelector(`[data-row="${row + 1}"][data-col="0"]`);
						firstCellNextRow.focus();
					}
					break;

				default: break;
			}
		},

		newEmptyRow() {
			let row = [];
			for(let i = 0; i < this.columns.length; i++) {
				row[i] = {};
				row[i].value = "";
				row[i].error = null;
				row[i].required = (i === 0 || i === 1)? true : false;
			}
			return row;
		},

		isEmptyRow(row) {
			let isEmpty = true;
			for(let i = 0; i < this.columns.length; i++) {
				if(row[i].value) {
					isEmpty = false;
					break;
				}
			}
			return isEmpty;
		},

		handlePaste(event, index) {
			const pastedData = event.clipboardData.getData('text');
			const rows = pastedData.split("\n");

			if(rows.length > 1) {

				event.preventDefault();

				for (let i = 0; i < rows.length; i++) {
					rows[i] = rows[i].replace(/\r/g, '');

					if (rows[i].trim() === '') {
						break;
					}

					this.handleTabbedInputRow(rows[i], index + i);
				}
			}

			this.emitChange();
		},

		handleTabbedInputRow(value: string, index: number) {

			let rowObject = null;
			if (this.spreadsheetData.length > index) {
				rowObject = this.spreadsheetData[index];
			} else {
				rowObject = this.newEmptyRow();
				this.spreadsheetData.push(rowObject);
			}

			const values = value.split("\t");
			values.forEach((val, idx) => {
				rowObject[idx].value = this.handleColumnInput(idx, val);
			});

		},

		handleColumnInput(columnIndex: number, value: string) {
			if (this.columns[columnIndex]?.pasteTransformer) {
				return this.columns[columnIndex]?.pasteTransformer(value);
			}
			return value;
		},

		deleteRow(rowIndex) {
			this.spreadsheetData.splice(rowIndex, 1);
		},

		cleanUpSpreadsheetData() {
			// remove empty rows
			this.spreadsheetData = this.spreadsheetData.filter(row => {
				return !this.isEmptyRow(row);
			});
		},

		validateSpreadsheetData() {
			let valid = true;

			this.spreadsheetData.forEach((row, index) => {
				if(!this.validateRow(row)) {
					valid = false;
				}
			});
			// check if min age is lower than max age
			// ...

			if(!valid) {
				throw new Error('');
			}
		},

		changeValue(row, column, event) {
			event.target.value = this.handleColumnInput(column, event.target.value)
			this.emitChange();
		},

		emitChange() {

			// Cleanup empty rows
			const out = [];
			this.spreadsheetData.forEach(row => {
				if (!this.isEmptyRow(row)) {
					out.push(row);
				}
			});

			this.$emit('update:modelValue', out);
		},

		trySave() {
			// remove empty rows
			this.cleanUpSpreadsheetData();

			// validate data
			try {
				this.validateSpreadsheetData();
			} catch(e) {
				this.spreadsheetData.push(this.newEmptyRow());
				// console.error(e);
				return;
			}
			// if error from backend, add empty row again at the end
			// ...
		}

	},

	created() {
		if(this.spreadsheetData.length === 0) {
			this.spreadsheetData.push(this.newEmptyRow());
		}
	},

	mounted() {

		if (typeof(this.modelValue) === 'undefined') {
			this.spreadsheetData = this.modelValue;
		}

		// set focus to first cell of the spreadsheet
		const firstCell = this.$refs.spreadsheet.querySelector('tbody tr:first-child td:first-child textarea');
		setTimeout(() => {
			firstCell.focus();
		}, 300);
	},

};
</script>
